import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Label, Button, Icon, Form, Checkbox } from 'semantic-ui-react';
import Header from 'components/Header';
import CancelGoButtons from 'components/CancelGoButtons';
import API from 'utils/api';
import formatter from 'utils/formatter';
import MicroProductForm from './ui/MicroProductForm';

class MicroProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: null,
      product: null
    };
  }
  
  componentDidMount() {
    const productId = parseInt(this.props.match.params.productId);
    this.setState({ productId }, this.getProduct);
  }

  getProduct = () => {
    API.getMicroEstablishmentProduct(this.state.productId)
      .then((response) => {
        this.setState({ product: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleProductAvailability = () => {
    API.toggleMicroProductAvailability(this.state.productId)
      .then((response) => {
        this.getProduct();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    if (!this.state.product) { return null }

    if (this.state.editing) {
      return (
        <MicroProductForm {...this.state.product} editing={true} />
      )
    }
    
    return (
      <div>
        <Header text={`${this.state.product.name}`} subtitle={`${this.state.product.product_category.name}`}>
          <Button primary icon circular onClick={ () => this.setState({ editing: true }) }>
            <Icon name="pencil" />
          </Button>
        </Header>

        <div style={{ marginBottom: '1em', fontSize: '24px' }}>
          { formatter.currency(this.state.product.price) }
        </div>

        <div style={{ marginBottom: '1.5em', display: 'flex', justifyContent: 'space-between' }}>
          { this.state.product.out_of_stock ? (
            <Label color="red" style={{ marginRight: '0.5em' }}>
              no disponible
            </Label>
          ) : (
            <Label color="green" style={{ marginRight: '0.5em' }}>
              disponible
            </Label>
          )}

          <Button icon circular onClick={this.toggleProductAvailability} size="mini">
            { this.state.product.out_of_stock ? (
              <Icon name="check" />
            ) : (
              <Icon name="close" />
            )}
          </Button>
        </div>
        
        <p style={{ fontSize: '16px' }}>
          { this.state.product.desc ? this.state.product.desc : 'Sin descripción.' }
        </p>

        <Button fluid style={{ marginTop: '2em' }} as={Link} to="/micro/products">
          regresar
        </Button>
      </div>
    )
  }
}

export default withRouter(MicroProductPage);