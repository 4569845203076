import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button, Icon, Label } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import es from 'timeago.js/lib/lang/es';
import formatter from 'utils/formatter';
import Header from 'components/Header';

export default function OpenBills({ bills, newBillFn, editBillFn, deleteBillFn }) {
  const history = useHistory();
  
  timeago.register('es', es);
  
  const handleBillClick = (bill) => {
    const url = `/waiter/bills/${bill.id}`;
    history.push(url);
  }
  
  let headerText = 'Sin cuentas abiertas';
  
  if (bills.length === 1) {
    headerText = '1 cuenta abierta';
  }

  if (bills.length > 1) {
    headerText = `${bills.length} cuentas abiertas`;
  }
  
  return (
    <div>
      <Header text={headerText}>
        <Button circular icon primary onClick={newBillFn}>
          <Icon name="plus" />
        </Button>
      </Header>

      <div>
        { bills.map((bill) => {
          let billHeader = '';
          
          if (bill.note) {
            billHeader = bill.note;

            if (bill.table) {
              billHeader = `${bill.note} (${bill.table.name.toLowerCase()})`;
            }
          }
          else {
            billHeader = bill.table.name.toLowerCase();
          }

          return (
            <Card fluid key={bill.id}>
              <Card.Content
                onClick={ () => handleBillClick(bill) }
                style={{ cursor: 'pointer' }}
              >
                <Card.Header>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>
                    { billHeader }
                  </span>
                  { bill.paid_at ? (
                    <Label color="green">
                      pagado
                    </Label>
                  ) : (
                    <Label color="red">
                      sin pagar
                    </Label> 
                  )}
                </div>
                </Card.Header>
                <Card.Meta>
                  <span style={{ fontSize: '16px' }}>
                    #{bill.id}
                  </span>
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 'small' }}>
                <span>
                  <Icon name="clock outline" size='large' />
                  <span style={{ marginRight: '3px' }}>
                    abierta
                  </span>
                  <TimeAgo
                    datetime={bill.created_at}
                    locale="es"
                  />
                </span>
                <span style={{ fontWeight: 'normal', fontSize: '18px', color: 'black' }}>
                  { formatter.currency(bill.total) }
                </span>
                </div>
              </Card.Content>
            </Card>
          )
        })}
      </div>
    </div>
  )
}