import React from 'react';
import qs from 'qs';
import { Statistic, Grid, Card, Icon, Button, Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Pusher from 'pusher-js';
import formatter from 'utils/formatter';
import authHelpers from 'utils/authHelpers';
import API from 'utils/api';
import Header from 'components/Header';
import SelectReportsDate from './ui/SelectReportsDate';

class OwnerSalesReportsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null,
      reportsByEstablishment: [],
      justUpdated: true,
      selectNewDate: false,
      total: 0,
      lastUpdatedEstablishmentId: null
    };
  }
  
  componentDidMount() {
    this.getParams();

    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      Pusher.logToConsole = true;
    }

    this.subscribeToPusherEvents();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getParams();
    }
  }

  getParams = () => {
    // Check if the date is set on the url
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const dateParam = params['date'];
    
    // If there is a date on the URL,
    // convert it to JS Date for state,
    // otherwise, just set for today.
    // then fetch the data;
    if (dateParam) {
      const decodedDateString = decodeURIComponent(dateParam);
      const date = new Date(decodedDateString);
      this.setState({ date }, this.getData)
    } else {
      this.setState({ date: new Date() }, this.getData);
    }
  }

  getData = () => {
    // Convert the JavaScript date to ISO 8601 format
    const isoDateString = this.state.date.toISOString();

    // Encode the ISO date string for use in a URL
    const encodedDate = encodeURIComponent(isoDateString);

    API.getOwnerSalesReports(encodedDate)
      .then((response) => {
        this.setState({ 
          reportsByEstablishment: response.data,
          justUpdated: true,
          selectNewDate: false
         });

        let total = 0;
        
        response.data.forEach(establishment => {
          total += establishment.total;
        });

        this.setState({ total }, this.resetTotalStyles);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  resetTotalStyles = () => {
    window.setTimeout(() => {
      this.setState({ justUpdated: false });
    }, 500);
  }

  subscribeToPusherEvents = () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true
    });
    
    const channelName = authHelpers.getChannelId();
    const channel = pusher.subscribe(channelName);
    
    channel.bind('bill-closed', (data) => {
      this.setState({ lastUpdatedEstablishmentId: data.last_updated_establishment_id }, this.getData)
    });
  }

  changeDate = (date) => {
    this.setState({
      selectNewDate: false
     });
    
    //  TODO: redirect to page with url
    const isoDateString = date.toISOString();
    const encodedDate = encodeURIComponent(isoDateString);
    const url = `/owner/sales?date=${encodedDate}`;
    this.props.history.push(url);
  }

  render() {
    if (!this.state.date) { return null }

    if (this.state.selectNewDate) {
      return (
        <SelectReportsDate
          initialDate={this.state.date}
          onChangeFn={ (date) => this.changeDate(date) }
        />
      )
    }

    let totalStyle = {};

    if (this.state.justUpdated) {
      totalStyle['fontWeight'] = 'bold';
    }

    const subtitle = format(this.state.date, 'd MMM yyyy', { locale: es });

    return (
      <div>
        <Header text="Ventas totales del día" subtitle={subtitle}>
          <Button
            circular
            primary
            icon="calendar"
            onClick={ () => this.setState({ selectNewDate: true }) } 
          />
        </Header>
        
        <Statistic.Group>
          <Statistic>
            <Statistic.Value>
              <span style={totalStyle}>
                { formatter.currency(this.state.total) }
              </span>
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>
        
        <div style={{ marginTop: '2em', paddingBottom: '2em' }}>
          { this.state.reportsByEstablishment.map((report) => {
            let cardColor = 'grey';
            let style = {};

            if (report.establishment.id === this.state.lastUpdatedEstablishmentId) {
              cardColor = 'blue';
              
              if (this.state.justUpdated) {
                style['fontWeight'] = 'bold';
              }
            } else {
              style['color'] = 'black';
            }

            const isoDateString = this.state.date.toISOString();
            const encodedDate = encodeURIComponent(isoDateString);
            const url = `/owner/sales/${report.establishment.id}?date=${encodedDate}`;
            
            return (
              <Card
                fluid
                color={cardColor}
                onClick={ () => this.props.history.push(url) }
                key={report.establishment.id}
              >
                <Card.Content>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={11} verticalAlign="middle">
                        { report.establishment.name }
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle" textAlign="right" style={style}>
                        { formatter.currency(report.total) }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
              </Card>
            )
          })}
        </div>
      </div>
    )
  }
}

export default withRouter(OwnerSalesReportsPage);