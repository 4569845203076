import React, { useState, useEffect } from 'react';
import { Button, Icon, Card, Label } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';

export default function MicroBillOrders({ bill, orders, toggleOrderReadyFn }) {
  const [pendingOrdersText, setPendingOrdersText] = useState('Sin ordenes pendientes');

  const toggleOrderReadyStatus = (order) => {
    toggleOrderReadyFn(order.id);
  }
  
  useEffect(() => {
    let pendingOrders = 0;
    
    orders.forEach(order => {
      if (order.ready_at === null) {
        pendingOrders += 1;
      }
    });

    if (pendingOrders === 0) {
      setPendingOrdersText('Pedido listo!');
    }

    if (pendingOrders === 1) {
      setPendingOrdersText('1 orden pendiente');
    }
  
    if (pendingOrders > 1) {
      setPendingOrdersText(`${pendingOrders} ordenes pendientes`)
    }
  }, [orders]);

  if (!orders || orders.length === 0) { return null }

  return (
    <Card fluid style={{ marginBottom: '2em' }}>
      <Card.Content>
        <Card.Header textAlign='center'>
          { pendingOrdersText.toUpperCase() }
        </Card.Header>
        <Card.Description>
          { orders.map((order) => {
            const textDecorationStyle = order.ready_at ? 'line-through' : 'none';
            const textColorStyle = order.ready_at ? 'darkgray' : '';
            
            return (
              <div key={order.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
                <p style={{ marginBottom: 0, color: textColorStyle, textDecoration: textDecorationStyle }}>
                  <b style={{ marginRight: '0.5em', fontSize: '20px' }}>
                    { order.quantity }
                  </b>
                  <span style={{ fontSize: '18px' }}>
                    { order.product.name }
                  </span>
                  { (order.ready_at !== null) &&
                    <span style={{ marginLeft: '0.5em' }}>
                      <Icon name="check" size="large" color="green" />
                    </span>
                  }
                </p>
                <span style={{ paddingLeft: '1em' }}>
                  { (order.ready_at === null) ? (
                    <Button
                      size="tiny"
                      icon
                      circular
                      onClick={ () => toggleOrderReadyStatus(order) }
                    >
                      <Icon name="check" />
                    </Button>
                  ) : (
                    <Button
                      size="tiny"
                      icon
                      circular
                      onClick={ () => toggleOrderReadyStatus(order) }
                    >
                      <Icon name="close" />
                    </Button>
                  )}
                </span>
              </div>
            )
          })}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            <Icon name="clock outline" size='large' />
            <span style={{ marginRight: '5px'}}>
              Ordenado
            </span>
            <TimeAgo
              datetime={bill.created_at}
              locale="es"
            />
          </span>
          { bill.paid_at ? (
            <Label color="green">
              pagado
            </Label>
          ) : (
            <Label color="red">
              sin pagar
            </Label> 
          )}
        </div>
      </Card.Content>
    </Card>
  )
}