import React from 'react';
import Message from 'components/Message';
import CancelGoButtons from 'components/CancelGoButtons';

export default function Confirmation({ headerText, bodyText, cancelText="cancelar", cancelFn, confirmText="confirmar", confirmFn, enabled, warning=false }) {
  const style = {
    marginTop: '1em'
  };
  
  return (
    <div style={style}>
      <Message headerText={headerText} bodyText={bodyText}>
        <CancelGoButtons
          cancelText={cancelText}
          cancelFn={cancelFn}
          goFn={confirmFn}
          goText={confirmText}
          goEnabled={enabled}
          warning={warning}
        />
      </Message>
    </div>
  )
}