import React, { useState, useEffect } from 'react';
import { Button, Icon, Input, List, Card } from 'semantic-ui-react';
import Header from 'components/Header';
import API from 'utils/api';

export default function WaiterDancerList({ order, cancelFn, onDancerSelectedFn }) {
  const [input, setInput] = useState('');
  const [dancers, setDancers] = useState([]);
  const [filteredDancers, setFilteredDancers] = useState(dancers);

  const filterDancers = () => {
    const regex = new RegExp(input, 'i');
      
    const filteredDancers = dancers.filter((dancer) => {
      return regex.test(dancer.name);
    });

    setFilteredDancers(filteredDancers);
  }

  useEffect(() => {
    API.getWaiterEstablishmentDancers()
      .then((response) => {
        setDancers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    filterDancers();
  }, [dancers, input])

  return (
    <div>
      <Header text={`Asignar ${order.product.name}`}>
        <Button circular icon onClick={cancelFn}>
          <Icon name="cancel" />
        </Button>
      </Header>

      <div>
        <Input
          fluid
          placeholder="buscar..." 
          onChange={ (event) => setInput(event.target.value) }
          value={input}
        />

        { (dancers.length > 0) &&
          <Card fluid>
            <Card.Content>
              <List divided>
                { filteredDancers.map((dancer) =>
                  <List.Item
                    key={dancer.id}
                    style={{ cursor: 'pointer', padding: '0.5em 0' }}
                    onClick={ () => onDancerSelectedFn(dancer) }
                  >
                    <div style={{ padding: '0.5em 0', fontSize: '16px' }}>
                      { dancer.name }  
                    </div>
                  </List.Item>
                )}
              </List>
            </Card.Content>
          </Card>
        }
      </div>
    </div>
  )
}