import React, { useState, useEffect } from 'react';
import { Button, Icon, Card } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import es from 'timeago.js/lib/lang/es';

timeago.register('es', es);

export default function ChefBill({ orders, toggleOrderReadyFn, deliverBillFn }) {
  const [pendingOrdersText, setPendingOrdersText] = useState('Sin ordenes pendientes');
  const [ready, setReady] = useState(false);

  const isMoreThanOneMinuteAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = (now - date) / 1000;
    return differenceInSeconds > 60;
  }

  const toggleOrderReadyStatus = (order) => {
    toggleOrderReadyFn(order.id);
  }
  
  useEffect(() => {
    let pendingOrders = 0;
    
    orders.forEach(order => {
      if (order.ready_at === null) {
        pendingOrders += 1;
      }
    });

    if (pendingOrders === 0) {
      setPendingOrdersText('Pedido listo para entrega');
      setReady(true);
    }

    if (pendingOrders === 1) {
      setPendingOrdersText('1 orden pendiente');
      setReady(false);
    }
  
    if (pendingOrders > 1) {
      setPendingOrdersText(`${pendingOrders} ordenes pendientes`)
      setReady(false);
    }
  }, [orders]);

  if (!orders || orders.length === 0) { return null }

  const billId = orders[0].bill_id;

  return (
    <Card fluid style={{ marginBottom: '2em' }}>
      <Card.Content>
        <Card.Header>
          #{billId} / {orders[0].bill.note}
        </Card.Header>
        <Card.Meta>
        { pendingOrdersText.toUpperCase() }
          
        </Card.Meta>
        <Card.Description>
          { orders.map((order) => {
            return (
              <div key={order.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
                <p style={{ marginBottom: 0 }}>
                  <b style={{ marginRight: '0.5em', fontSize: '20px' }}>
                    { order.quantity }
                  </b>
                  <span style={{ fontSize: '18px' }}>
                    { order.product.name }
                  </span>
                  { (order.ready_at !== null) &&
                    <span style={{ marginLeft: '0.5em' }}>
                      <Icon name="check" color="green" size="large" />
                    </span>
                  }
                </p>
                { (order.ready_at === null) ? (
                  <Button
                    size="tiny"
                    icon
                    circular
                    onClick={ () => toggleOrderReadyStatus(order) }
                  >
                    <Icon name="check" />
                  </Button>
                ) : (
                  <Button
                    size="tiny"
                    icon
                    circular
                    onClick={ () => toggleOrderReadyStatus(order) }
                  >
                    <Icon name="close" />
                  </Button>
                )}
              </div>
            )
          })}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            { isMoreThanOneMinuteAgo(orders[orders.length-1].created_at) ? (
              <>
                <Icon name="clock outline" size='large' />
                <TimeAgo
                  datetime={orders[orders.length-1].created_at}
                  locale="es"
                />
              </>
            ) : (
              <span style={{ marginRight: '3px' }}>
                Recién Ordenado
              </span>
            )}
          </div>
          <div>
            <Button
              disabled={!ready}
              color={ready ? 'blue' : 'grey'}
              onClick={ () => deliverBillFn(billId) }
            >
              entregar pedido
            </Button>
          </div>
        </div>
      </Card.Content>
    </Card>
  )
}