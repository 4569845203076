import React, { useState, useEffect } from 'react';
import { List, Grid, Card } from 'semantic-ui-react';
import formatter from 'utils/formatter';
import Header from 'components/Header';

export default function BillOverview({ orders }) {
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [groupedOrders, setGroupedOrders] = useState([]);
  
  const calculateTotalPrice = () => {
    let total = 0.0;
    
    orders.forEach((order) => {
      const orderPrice = order.price;
      total += orderPrice;
    });

    return total
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
    
    const ordersByName = {};

    orders.forEach((order) => {
      const key = order.product_id;
      
      if (!ordersByName[key]) {
        ordersByName[key] = {
          quantity: 0,
          totalCost: 0,
          name: order.name,
          price: order.price
        };
      }

      ordersByName[key].quantity++;
      ordersByName[key].totalCost += order.price;
    });

    setGroupedOrders(ordersByName);
  }, []);

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <List divided relaxed>
            { Object.keys(groupedOrders).map((productName) => {
              const order = groupedOrders[productName];
              
              return (
                <List.Item key={productName}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={12} verticalAlign="top">
                        <p style={{ fontSize: '1em', marginBottom: 0 }}>
                          { order.name }
                        </p>
                        { (order.quantity > 1) &&
                          <p style={{ fontSize: '1em', marginBottom: 0 }}>
                            x {order.quantity}
                          </p>
                        }
                      </Grid.Column>
                      
                      <Grid.Column width={4} verticalAlign="top" textAlign="right">
                        <p style={{ fontSize: '1em', marginBottom: 0, color: (order.quantity > 1 ? 'gray' : '') }}>
                          { formatter.currency(order.price) }
                        </p>
                        { (order.quantity > 1) &&
                          <p style={{ fontSize: '1em', marginBottom: 0 }}>
                            {formatter.currency((order.quantity * order.price))}
                          </p>
                        }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </List.Item>
              )
            })}
          </List>
        </Card.Content>
      </Card>
      
      <Card fluid color="blue">
        <Card.Header>
          <List relaxed>
            <List.Item>
              <div style={{ textAlign: 'right', padding: '1em', fontSize: 'large' }}>
                Total pagado
                <span style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                { formatter.currency(totalPrice) }
                </span>
              </div>
            </List.Item>
          </List>
        </Card.Header>
      </Card>
    </div>
  )
}