import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Header from 'components/Header';
import { Button } from 'semantic-ui-react';
import MicroOpenBillCard from './ui/MicroOpenBillCard';
import API from 'utils/api';

class MicroBillsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: []
    };
  }

  componentDidMount() {
    this.getBills();
  }

  getBills = () => {
    API.getMicroBills()
      .then((response) => {
        this.setState({ bills: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    if (!this.state.bills) { return null }

    // set header
    let headerText = 'Sin cuentas abiertas';

    if (this.state.bills.length === 1) {
      headerText = '1 cuenta abierta';
    }

    if (this.state.bills.length > 1) {
      headerText = `${this.state.bills.length} cuentas abiertas`;
    }
    
    return (
      <div>
        <Header text={headerText}>
        </Header>

        <div>
          { this.state.bills.map((bill) => {
            return (
              <MicroOpenBillCard bill={bill} key={bill.id} />
            )
          })}
        </div>

        <Button
          fluid
          primary
          as={Link}
          to="/micro/bills/new"
          style={{ marginTop: '2em', marginBottom: '3em' }}
        >
          agregar cuenta nueva
        </Button>
      </div>
    )
  }
}

export default withRouter(MicroBillsPage);