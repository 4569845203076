import React from 'react';

export default function PoweredBy(props) {
  const styles = {
    padding: '2em 1em 1em 1em',
    textAlign: 'center',
    fontSize: 'small'
  };
  
  return (
    <div style={styles}>
      powered by
      {' '}
      <a
        href="https://restaurantero.io"
        rel="noopener noreferrer"
        target="_blank"
      >
        restaurantero.io
      </a>
    </div>
  )
}