import React, { useState, useEffect } from 'react';
import { Form, Input, Select } from 'semantic-ui-react';
import API from 'utils/api';
import Header from 'components/Header';
import CancelGoButtons from 'components/CancelGoButtons';

export default function OwnerForm({ cancelFn, submitFn, owner=null }) {
  const [valid, setValid] = useState(false);
  const [name, setName] = useState(owner?.name || '');
  const [phoneNumber, setPhoneNumber] = useState(owner?.phone_number || '');

  const handleName = (event) => {
    setName(event.target.value);
  }

  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  }

  const validate = () => {
    let valid = true;
    
    if (!name) {
      valid = false;
    }

    if (!phoneNumber) {
      valid = false;
    }
    
    setValid(valid);
  }

  const submit = () => {
    if (valid) {
      if (owner) {
        submitFn(owner, name, phoneNumber);
      } else {
        submitFn(name, phoneNumber);
      }
    }
  }

  useEffect(() => {
    validate();
  }, [name, phoneNumber])

  const goText = owner ? 'actualizar dueño' : 'inscribir dueño';

  return (
    <div>
      <Header text="Inscribir nuevo dueño" />
      
      <Form>
        <Form.Field>
          <label>nombre</label>
          <Input
            value={name}
            onChange={handleName}
          />
        </Form.Field>

        <Form.Field>
          <label>teléfono móvil</label>
          <Input
            value={phoneNumber}
            onChange={handlePhoneNumber}
          />
        </Form.Field>
        
        <CancelGoButtons
          cancelFn={cancelFn}
          goText={goText}
          goFn={submit}
          goEnabled={valid}
        />
      </Form>
    </div>
  )
}