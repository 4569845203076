import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import formatter from 'utils/formatter';

export default function DancerOrderCard({ order, paid=false }) {
  const isMoreThanOneMinuteAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = (now - date) / 1000;
    return differenceInSeconds > 60;
  }

  return (
    <Card fluid style={{ marginBottom: '1em' }}>
      <Card.Content>
        <Card.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ marginBottom: 0, fontSize: '16px' }}>
              <b style={{ marginRight: '0.5em' }}>
                { order.quantity }
              </b>
              <span>
                { order.product_name }  
              </span>
              { order.notes &&
                <span style={{ marginLeft: '8px', fontSize: '13px', color: 'gray' }}>
                  { order.notes }
                </span>
              }
            </div>
            <div>
              <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                { formatter.currency(order.commission) }
              </span>
            </div>
          </div>
        </Card.Header>
        { order.notes &&
          <Card.Meta>
            <span style={{ fontSize: '16px' }}>
              { order.notes.toUpperCase() }
            </span>
          </Card.Meta>
        }
        <Card.Description>
          { order.paid_at &&
            <div style={{ color: 'black', marginBottom: '5px' }}>
              <Icon name="clock outline" size='large' />
              <span style={{ marginRight: '5px' }}>pagado</span>
              <TimeAgo
                datetime={order.paid_at}
                locale="es"
              />
            </div>
          }
          { order.delivered_at &&
            <div style={{ marginBottom: '5px' }}>
              <Icon name="clock outline" size='large' />
              <span style={{ marginRight: '5px' }}>entregado</span>
              <TimeAgo
                datetime={order.delivered_at}
                locale="es"
              />
            </div>
          }
          <div style={{ color: 'darkgray' }}>
            <Icon name="clock outline" size='large' />
            <span style={{ marginRight: '5px' }}>ordenado</span>
            <TimeAgo
              datetime={order.created_at}
              locale="es"
            />
          </div>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}