import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Button, Card, Statistic } from 'semantic-ui-react';
import { addDays, subDays } from 'date-fns';
import Header from 'components/Header';
import MicroClosedBillCard from './ui/MicroClosedBillCard';
import API from 'utils/api';
import formatter from 'utils/formatter';
import { format, isTomorrow, differenceInDays } from 'date-fns';
import { es } from 'date-fns/locale';

class MicroClosedBillsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      bills: []
    };
  }

  componentDidMount() {
    this.getBills();
  }

  getBills = () => {
    API.getMicroClosedBills(this.state.date)
      .then((response) => {
        this.setState({ bills: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  goToPrevDay = () => {
    const date = this.state.date;
    const prevDate = subDays(date, 1);
    this.setState({ date: prevDate }, this.getBills)
  }

  goToNextDay = () => {
    const date = this.state.date;
    const nextDate = addDays(date, 1);
    this.setState({ date: nextDate }, this.getBills)
  }

  getHeaderText = () => {
    return format(this.state.date, "dd 'de' MMMM 'del' yyyy", { locale: es });
  }

  getSubheaderText = () => {
    const today = new Date();
    
    if (
      this.state.date.getDate() === today.getDate() &&
      this.state.date.getMonth() === today.getMonth() &&
      this.state.date.getFullYear() === today.getFullYear()
    ) {
      return 'HOY';
    }

    const diff = differenceInDays(this.state.date, today);
    let text = '';

    if (diff === -1) {
      text = 'AYER';
    } else {
      text = `HACE ${-diff} DIAS`;
    }
    
    return text
  }

  getTotal = () => {
    let total = 0;

    this.state.bills.forEach((bill) => {
      total += bill.total;
    });

    return total
  }

  render() {
    if (!this.state.bills) { return null }

    const date = this.state.date;
    const nextDate = addDays(date, 1);
    
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>
            <Button size="tiny" circular icon onClick={this.goToPrevDay}>
              <Icon name="chevron left" />
            </Button>
          </span>
          <Header text={ this.getHeaderText() } subtitle={ this.getSubheaderText() } />
          <span>
            <Button size="tiny" disabled={isTomorrow(nextDate)} circular icon onClick={this.goToNextDay}>
              <Icon name="chevron right" />
            </Button>
          </span>
        </div>
  
        <Card fluid>
          <Card.Content textAlign='center'>
            <Statistic>
              <Statistic.Label>
                ventas totales
              </Statistic.Label>
              <Statistic.Value>
                <span>
                  { formatter.currency(this.getTotal()) }
                </span>
              </Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
  
        <div>
          { this.state.bills.map((bill) => {
            return (
              <MicroClosedBillCard bill={bill} key={bill.id} />
            )
          })}
        </div>
      </div>
    )
  }
}

export default withRouter(MicroClosedBillsPage);