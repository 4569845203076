import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from 'components/Header';
import { Form, Input, Select } from 'semantic-ui-react';
import CancelGoButtons from 'components/CancelGoButtons';
import API from 'utils/api';

class MicroProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      categories: [],
      product_category_id: props.product_category_id || null,
      name: props.name || '',
      price: props.price || '',
      desc: props.desc || ''
    };
  }

  componentDidMount() {
    API.getMicroEstablishmentCategories()
      .then((response) => {
        console.log(response.data);
        this.setState({ categories: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleCategoryId = (event, data) => {
    this.setState({ product_category_id: data.value}, this.validate);
  }

  handleName = (event) => {
    this.setState({ name: event.target.value }, this.validate);
  }

  handlePrice = (event) => {
    const price = parseFloat(event.target.value);

    if (event.target.value === '') {
      this.setState({ price: '' }, this.validate);
    }

    if (!isNaN(price)) {
      this.setState({ price: event.target.value }, this.validate);
    }
  }

  handleDesc = (event) => {
    this.setState({ desc: event.target.value }, this.validate);
  }
  
  validate = () => {
    let valid = true;
    if (this.state.product_category_id === '') { valid = false; }
    if (this.state.name === '') { valid = false; }
    if (this.state.price === '') { valid = false; }
    if (isNaN(parseFloat(this.state.price))) { valid = false; }
    this.setState({ valid });
  }

  createProduct = () => {
    if (this.state.valid) {
      const params = this.state;

      API.createMicroProduct(params)
        .then((response) => {
          this.props.history.push('/micro/products');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  updateProduct = () => {
    if (this.state.valid) {
      const params = this.state;

      API.updateMicroEstablishmentProduct(this.props.id, params)
        .then((response) => {
          this.props.history.push('/micro/products');
        })
        .catch((error) => {
          console.error(error);
        })
    }
  }

  render() {
    if (this.state.categories.length === 0) { return null }

    return (
      <div>
        <Header text={this.props.editing ? 'Editar producto' : 'Nuevo producto'} subtitle="actualizar el menú" />
        
        <Form as="div">
          <Form.Field>
            <label>Categoría del menú</label>
            <Select
              onChange={this.handleCategoryId}
              value={this.state.product_category_id}
              options={
                this.state.categories.map((category) => {
                  return {
                    key: category.id,
                    text: category.name,
                    value: category.id
                  }
                })
              }
            />
          </Form.Field>
          
          <Form.Field>
            <label>Nombre del producto</label>
            <Input
              value={this.state.name}
              onChange={this.handleName}
              placeholder="Ejemplo: Paste tradicional de papa con carne"
            />
          </Form.Field>

          <Form.Field>
            <label>Precio unitario</label>
            <Input
              type="number"
              step="0.10"
              icon="dollar sign"
              iconPosition="left"
              value={this.state.price}
              onChange={this.handlePrice}
            />
          </Form.Field>
          
          <Form.Field>
            <label>Descripción del producto</label>
            <textarea
              placeholder='descripción del producto'
              onChange={this.handleDesc}
              value={this.state.desc}
            />
          </Form.Field>
          
          <CancelGoButtons
            cancelFn={ () => this.props.history.push('/micro/products') }
            goText={this.props.editing ? 'actualizar producto' : 'crear producto' }
            goFn={this.props.editing ? this.updateProduct : this.createProduct}
            goEnabled={this.state.valid}
          />
        </Form>
      </div>
    )
  }
}

export default withRouter(MicroProductForm);