import React, { useState } from 'react';
import { List, Grid, Form, Card, Input } from 'semantic-ui-react';
import formatter from 'utils/formatter';
import Header from 'components/Header';
import Confirmation from 'components/Confirmation';
import CancelGoButtons from 'components/CancelGoButtons';

export default function BillSummary({ bill, cancelFn, closeBillFn }) {
  const [tipPercentage, setTipPercentage] = useState('10');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleTip = (event) => {
    const tip = parseInt(event.target.value);
    
    if (tip >= 0) {
      setTipPercentage(tip)
    } else {
      setTipPercentage('')
    }
  }

  if (showConfirmation) {
    const tableNote = bill.note ? `(${bill.note})` : '';
    const amount = formatter.currency(bill.total + ((bill.total * tipPercentage) / 100));
    const text = `Confirma que la cuenta #${bill.id} (${tableNote}) ha sido pagada por ${amount} y puede ser cerrada.`;

    return (
      <Confirmation 
        headerText="Cerrar cuenta pagada"
        bodyText={text}
        cancelFn={ () => setShowConfirmation(false) }
        confirmFn={closeBillFn}
        confirmText="Cerrar cuenta"
        enabled={true}
        warning={true}
      />
    )
  }

  let billHeader = '';
          
  if (bill.note) {
    billHeader = bill.note;

    if (bill.table) {
      billHeader = `${bill.note} (${bill.table.name.toLowerCase()})`;
    }
  }
  else {
    billHeader = bill.table.name.toLowerCase();
  }

  const subtitle = `#${bill.id}`;

  return (
    <div>
      <Header text={billHeader} subtitle={subtitle} />

      <Card fluid>
        <Card.Content>
          <List divided relaxed>
            { bill.orders.map((order) => 
              <List.Item key={order.id}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12} verticalAlign="middle">
                      <p style={{ fontSize: '1em', marginBottom: 0 }}>
                        <span>{ order.product.name }</span>
                        { order.dancer_id &&
                          <span style={{ marginLeft: '0.5em', color: 'gray' }}>
                            ({ order.dancer_name })
                          </span>
                        }
                      </p>
                      <p style={{ fontWeight: 'normal', fontSize: '1em', color: 'gray' }}>
                        { order.notes }
                      </p>
                    </Grid.Column>
                    
                    <Grid.Column width={4} verticalAlign="middle" textAlign="right">
                      <div>
                        { formatter.currency(order.product.price) }
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Item>
            )}
            <List.Item>
              <div style={{ textAlign: 'right' }}>
                Sub total
                <span style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                  { formatter.currency(bill.total) }
                </span>
              </div>
            </List.Item>
          </List>
        </Card.Content>
      </Card>
      
      <Form style={{ display: 'flex', justifyContent: 'end' }}>
        <Form.Field style={{ width: '20%' }}>
          <Input
            type="number"
            value={tipPercentage}
            onChange={handleTip}
            icon="percent"
            placeholder="propina"
          />
        </Form.Field>
      </Form>
      
      <Card fluid color="black">
        <Card.Header>
          <List relaxed>
            <List.Item>
              <div style={{ textAlign: 'right', padding: '1em', fontSize: 'large' }}>
                Total a pagar
                <span style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                { formatter.currency(bill.total + ((bill.total * tipPercentage) / 100)) }
                </span>
              </div>
            </List.Item>
          </List>
        </Card.Header>
      </Card>
      
      <CancelGoButtons
        cancelFn={cancelFn}
        goText="cerrar cuenta pagada"
        goFn={() => setShowConfirmation(true) }
        goEnabled={true}
      />
    </div>
  )
}