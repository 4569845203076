import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import {
  OwnerRoute,
  ManagerRoute,
  ChefRoute,
  WaiterRoute,
  CashierRoute,
  ReferrerRoute,
  MicroRoute,
  DancerRoute
} from 'utils/routes';

import HomePage from 'pages/HomePage';
import AuthPage from 'pages/AuthPage';

import MenuPage from 'pages/MenuPage';
import EstablishmentOrderTokenPage from 'pages/establishment/EstablishmentOrderTokenPage';

import MicroNewBillPage             from 'pages/micro/MicroNewBillPage';
import MicroBillsPage               from 'pages/micro/MicroBillsPage';
import MicroClosedBillsPage         from 'pages/micro/MicroClosedBillsPage';
import MicroSalesByWeekPage         from 'pages/micro/MicroSalesByWeekPage';
import MicroSalesByMonthPage        from 'pages/micro/MicroSalesByMonthPage';
import MicroBillPage                from 'pages/micro/MicroBillPage';
import MicroProductsPage            from 'pages/micro/MicroProductsPage';
import MicroProductPage             from 'pages/micro/MicroProductPage';
import MicroProductForm             from 'pages/micro/ui/MicroProductForm';

import CashierBillsOrdersPage from 'pages/cashier/CashierBillsOrdersPage';

import WaiterBillsPage from 'pages/waiter/WaiterBillsPage';
import WaiterBillPage  from 'pages/waiter/WaiterBillPage';

import DancerOrdersPage from 'pages/dancer/DancerOrdersPage';
import DancerPaidOrdersPage from 'pages/dancer/DancerPaidOrdersPage';

import ChefOrdersPage from 'pages/chef/ChefOrdersPage';

import OwnerSalesReportsPage             from 'pages/owner/OwnerSalesReportsPage';
import OwnerEstablishmentSalesReportPage from 'pages/owner/OwnerEstablishmentSalesReportPage';

import ManagerProductsPage from 'pages/manager/ManagerProductsPage';

import ReferrerCommissionsPage from 'pages/referrer/ReferrerComissionsPage';

function App(props) {
  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  return (
    <BrowserRouter>
      <div id="app" style={appStyle}>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/establishments/:uuid" exact>
            <MenuPage />
          </Route>

          <Route path="/establishments/:establishmentId/ordertkns/:orderTokenId" exact>
            <EstablishmentOrderTokenPage />
          </Route>
          
          
          <Route path="/auth/:user" exact>
            <AuthPage />
          </Route>

          
          <MicroRoute path="/micro/bills/new" exact>
            <MicroNewBillPage />
          </MicroRoute>

          <MicroRoute path="/micro/closed-bills" exact>
            <MicroClosedBillsPage />
          </MicroRoute>

          <MicroRoute path="/micro/closed-bills/weekly" exact>
            <MicroSalesByWeekPage />
          </MicroRoute>

          <MicroRoute path="/micro/closed-bills/monthly" exact>
            <MicroSalesByMonthPage />
          </MicroRoute>

          <MicroRoute path="/micro/bills" exact>
            <MicroBillsPage />
          </MicroRoute>

          <MicroRoute path="/micro/bills/:billId" exact>
            <MicroBillPage />
          </MicroRoute>

          <MicroRoute path="/micro/products/new" exact>
            <MicroProductForm />
          </MicroRoute>

          <MicroRoute path="/micro/products" exact>
            <MicroProductsPage />
          </MicroRoute>

          <MicroRoute path="/micro/products/:productId" exact>
            <MicroProductPage />
          </MicroRoute>

          
          <CashierRoute path="/cashier/orders" exact>
            <CashierBillsOrdersPage />
          </CashierRoute>

          
          <WaiterRoute path="/waiter/bills" exact>
            <WaiterBillsPage />
          </WaiterRoute>

          <WaiterRoute path="/waiter/bills/:billId" exact>
            <WaiterBillPage />
          </WaiterRoute>


          <DancerRoute path="/dancer/orders" exact>
            <DancerOrdersPage />
          </DancerRoute>

          <DancerRoute path="/dancer/paid-orders" exact>
            <DancerPaidOrdersPage />
          </DancerRoute>


          <ChefRoute path="/chef/orders" exact>
            <ChefOrdersPage />
          </ChefRoute>

          
          <OwnerRoute path="/owner/sales" exact>
            <OwnerSalesReportsPage />
          </OwnerRoute>

          <OwnerRoute path="/owner/sales/:establishmentId" exact>
            <OwnerEstablishmentSalesReportPage />
          </OwnerRoute>

          
          <ManagerRoute path="/manager/products" exact>
            <ManagerProductsPage />
          </ManagerRoute>

          
          <ReferrerRoute path="/referrer/commissions" exact>
            <ReferrerCommissionsPage />
          </ReferrerRoute>
        </Switch>
      </div>
    </BrowserRouter>
  )  
}

export default App;