import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Container, Header } from 'semantic-ui-react';
import jsQR from 'jsqr';


class Scanner extends React.Component {  
  constructor(props) {
    super(props);
    
    this.canvasRef = React.createRef();
    this.videoRef = React.createRef();
    
    this.state = {
      scanning: true,
      codeIsValid: false,
      message: ''
    };
  }
  
  componentDidMount() {
    this.getVideoStream();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getVideoStream();
    }
  }
  
  getVideoStream = () => {
    if (
      this.state.scanning &&
      window.navigator.mediaDevices &&
      window.navigator.mediaDevices.getUserMedia
    ) {
      const UserMediaConstraints = {
        audio: false,
        video: { facingMode: "environment" }
      };
      
      window.navigator.mediaDevices.getUserMedia(UserMediaConstraints)
        .then((stream) => {
          this.videoRef.current.srcObject = stream;
          this.videoRef.current.play();
          requestAnimationFrame(this.tick)
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
  
  
  tick = () => {
    const video = this.videoRef.current;
    const canvas = this.canvasRef.current;
    
    canvas.height = video.height;
    canvas.width = video.width;
    
    const canvasCtx = canvas.getContext('2d');
    
    canvasCtx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvasCtx.getImageData(0, 0, canvas.width, canvas.height);
    
    const opts = { inversionAttempts: 'dontInvert' };
    const code = jsQR(imageData.data, imageData.width, imageData.height, opts);
    
    if (code) {
      console.log(code);
      this.closeCamera();
      // this.handleQRcode(code);
    }
    else {
      requestAnimationFrame(this.tick);
    }
  }


  closeCamera = () => {
    const stream = this.videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(track => {
      track.stop();
    });

    this.videoRef.current.srcObject = null;
    this.setState({ scanning: false });
  }
  
  
  handleQRcode = (code) => {
    const url = 'https://beta-api.chrysaetosapp.com/tickets/check';
    
    const data = {
      token: this.props.token,
      code: code.data
    };
    
    axios.post(url, data)
      .then((response) => {
        this.setState({
          scanning: false,
          codeIsValid: response.data.valid,
          message: response.data.message
        });
      })
      .catch((error) => {
        let message = 'Error de servidor.';
        
        if (error.response.data.errors) {
          message = error.response.data.errors[0];
        }
        
        this.setState({ 
          scanning: false,
          codeIsValid: false,
          message: message
        });
      });
  }
  
  restartScanning = () => {
    this.setState({
      scanning: true,
      currentCodeIsValid: false
    });
  }
  
  render() {
    let content = null;
    
    if (this.state.scanning) {
      content = (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Header as='h2'>VERIFICADOR</Header>
          
          <canvas ref={this.canvasRef} hidden />
          <video
            ref={this.videoRef}
            width="360px"
            height="360px"
            autoPlay
            playsInline
            muted
          />
        </div>
      )
    }
    else {
      content = (
        <p>Scanning</p>
      )
    }
    
    const style = {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    };
    
    return (
      <div style={style}>
        {content}
      </div>
    );
  }
  
}


export default withRouter(Scanner)