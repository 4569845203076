import React from 'react';
import qs from 'qs';
import { Link, withRouter } from 'react-router-dom';
import { Statistic, List, Button, Icon, Card, Breadcrumb } from 'semantic-ui-react';
import Pusher from 'pusher-js';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import formatter from 'utils/formatter';
import API from 'utils/api';
import authHelpers from 'utils/authHelpers';
import Header from 'components/Header';
import BillClosedEvent from './ui/BillClosedEvent';

class OwnerEstablishmentSalesReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishmentId: null,
      date: null,
      establishment: null,
      total: 0.0,
      bills: []
    }
  }
  
  componentDidMount() {

    // Get establishment id
    const establishmentId = this.props.match.params.establishmentId;
    
    // Get date
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const decodedDateString = decodeURIComponent(params.date);
    const date = new Date(decodedDateString)
    
    this.setState({ establishmentId, date }, this.getReports);
    
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      Pusher.logToConsole = true;
    }

    this.subscribeToPusherEvents();
  }

  subscribeToPusherEvents = () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true
    });
    
    const channelName = authHelpers.getChannelId();
    const channel = pusher.subscribe(channelName);
    
    channel.bind('bill-closed', (data) => {
      this.getReports();
    });
  }

  getReports() {
    API.getOwnerEstablishmentSalesReport(this.state.establishmentId, this.state.date)
      .then((response) => {
        this.setState({
          establishment: response.data.establishment,
          bills: response.data.bills,
          total: response.data.total
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {
    if (!this.state.establishment) { return null }

    const formattedDate = format(this.state.date, 'd MMMM yyyy', { locale: es });
    
    const isoDateString = this.state.date.toISOString();
    const encodedDate = encodeURIComponent(isoDateString);
    const breadCrumbLinkUrl = `/owner/sales?date=${encodedDate}`;

    return (
      <div>
        <Breadcrumb style={{ marginTop: '1em' }}>
          <Breadcrumb.Section style={{ fontWeight: 'bold' }}>
            <Link to={breadCrumbLinkUrl}>
              Ventas totales
            </Link>
          </Breadcrumb.Section>
          
          <Breadcrumb.Divider />
          
          <Breadcrumb.Section active style={{ fontWeight: 'normal' }}>
            { this.state.establishment.name }
          </Breadcrumb.Section>
        </Breadcrumb>

        <Header text={this.state.establishment.name} subtitle={`Ventas del ${formattedDate}`} />
          
        <Card fluid>
          <Card.Content textAlign='center'>
            <Statistic align>
              <Statistic.Label>
                ventas totales del dia
              </Statistic.Label>
              <Statistic.Value>
                <span style={{ fontWeight: 'bold' }}>
                  { formatter.currency(this.state.total) }
                </span>
              </Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
        
        <List relaxed divided style={{ padding: '0 0.5em', marginTop: '2em' }}>
          { this.state.bills.map((bill) => 
            <BillClosedEvent bill={bill} key={bill.id} />
          )}
        </List>
      </div>
    )
  }
}

export default withRouter(OwnerEstablishmentSalesReportPage);