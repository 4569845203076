import React from 'react';
import { withRouter } from 'react-router-dom';
import Pusher from 'pusher-js';
import qs from 'qs';
import API from 'utils/api';
import PoweredBy from 'ui/PoweredBy';
import BillOverview from './ui/BillOverview';

class EstablishmentOrderTokenPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      establishmentId: '',
      orderTokenId: '',
      name: '',
      bill: null,
      orders: []
    };
  }
  
  componentDidMount() {
    const query = qs.parse(this.props.match.params, { ignoreQueryPrefix: true });
    const establishmentId = query.establishmentId;
    const orderTokenId = query.orderTokenId;
    
    this.setState({ establishmentId, orderTokenId }, () => {
      this.getData();
      this.subscribeToPusherEvents();
    });
  }

  subscribeToPusherEvents = () => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      Pusher.logToConsole = true;
    }
    
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true
    });
    
    const channelName = `${this.state.establishmentId}:${this.state.orderTokenId}`;
    const channel = pusher.subscribe(channelName);
    
    channel.bind('order-token-update', (data) => {
      this.getData();
    });
  }

  getData = () => {
    API.getEstablishmentOrderToken(this.state.establishmentId, this.state.orderTokenId)
      .then((response) => {
        console.log(response.data);
        
        this.setState({
          name: response.data.name,
          bill: response.data.bill,
          orders: response.data.orders
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    if (!this.state.bill) { return null }

    return (
      <div style={{ paddingBottom: '2em', margin: '1em' }}>
        <h1 style={{ marginBottom: '1em', marginTop: '1em', textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}>
          { this.state.name }
        </h1>

        <h2 style={{ marginTop: 0, marginBottom: '0', textAlign: 'center', color: 'gray', fontWeight: 'normal', fontSize: '1.5em' }}>
          tu orden está en progreso
        </h2>

        <p style={{ fontSize: '20px', textAlign: 'center' }}>
          { this.state.bill.note }
        </p>

        <BillOverview bill={this.state.bill} orders={this.state.orders} />

        <PoweredBy />
      </div>
    )
  }
}

export default withRouter(EstablishmentOrderTokenPage);