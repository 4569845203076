import React from 'react';

export default function PageHeader({ text, subtitle, inlineSubtitle=false, children }) {
  const inlineSubtitleStyles = {
    display: 'flex',
    alignItems: 'center'
  }

  const subTitleInlineStyles = {
    marginRight: '0.5em',
    marginBottom: 0
  }
  
  return (
    <div
      style={{ margin: '1.5em 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <div style={inlineSubtitle ? inlineSubtitleStyles : null}>
        <h2 style={inlineSubtitle ? subTitleInlineStyles : { marginBottom: 0 }}>
          {text}
        </h2>
        <h3 style={{ marginTop: 0, color: 'gray' }}>
          { subtitle }
        </h3>
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}