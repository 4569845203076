import axios from 'axios';
import authHelpers from 'utils/authHelpers';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const API = {
  
  // PUBLIC
  getEstablishmentMenu: (establishmentId) => {
    const url = `${baseUrl}/establishments/${establishmentId}/menu`;
    return axios.get(url)
  },

  getEstablishmentOrderToken: (establishmentId, orderTokenId) => {
    const url = `${baseUrl}/establishments/${establishmentId}/orders/${orderTokenId}`;
    return axios.get(url)
  },
  
  // CASHIER
  getCashierEstablishmentMenu: () => {
    const url = `${baseUrl}/cashiers/establishment/menu`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  createCashierBill: (params) => {
    const url = `${baseUrl}/cashiers/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  assignCashierBill: (billId, params) => {
    const url = `${baseUrl}/cashiers/bills/${billId}/assign`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  
  // WAITER
  getWaiterEstablishmentTables: () => {
    const url = `${baseUrl}/waiters/establishment/tables`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getWaiterEstablishmentMenu: () => {
    const url = `${baseUrl}/waiters/establishment/menu`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getWaiterEstablishmentDancer: (dancerTokenUUID) => {
    const url = `${baseUrl}/waiters/establishment/dancer?uuid=${dancerTokenUUID}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getWaiterEstablishmentDancers: () => {
    const url = `${baseUrl}/waiters/establishment/dancers`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getWaiterBills: () => {
    const url = `${baseUrl}/waiters/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  createWaiterBill: (params) => {
    const url = `${baseUrl}/waiters/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  getWaiterBill: (billId) => {
    const url = `${baseUrl}/waiters/bills/${billId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  updateWaiterBill: (billId, params) => {
    const url = `${baseUrl}/waiters/bills/${billId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  createWaiterOrders: (billId, params) => {
    const url = `${baseUrl}/waiters/bills/${billId}/orders`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  waiterToggleDeliveredOrder: (orderId) => {
    const url = `${baseUrl}/waiters/orders/${orderId}/delivered`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  assignWaiterOrder: (orderId, params) => {
    const url = `${baseUrl}/waiters/orders/${orderId}/assign`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },
  
  closeWaiterBill: (billId) => {
    const url = `${baseUrl}/waiters/bills/${billId}/close`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  deleteWaiterBill: (billId) => {
    const url = `${baseUrl}/waiters/bills/${billId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.delete(url, headers)
  },

  // DANCER
  getDancerOrders: (paid=false) => {
    let url = `${baseUrl}/dancers/orders`;

    if (paid) {
      url += '?paid=true';
    }
    
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },
  
  // CHEF
  getChefOrders: () => {
    const url = `${baseUrl}/chefs/orders`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  toggleChefOrderReady: (orderId) => {
    const url = `${baseUrl}/chefs/orders/${orderId}/ready`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  deliverChefBill: (billId) => {
    const url = `${baseUrl}/chefs/bills/${billId}/deliver`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },
  
  // OWNER
  getOwnerSalesReports: (date) => {
    let url = `${baseUrl}/owners/sales?date=${date}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getOwnerEstablishmentSalesReport: (establishmentId, date) => {
    const url = `${baseUrl}/owners/sales/${establishmentId}?date=${date}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  // MICRO
  createMicroBill: (params) => {
    const url = `${baseUrl}/micros/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  getMicroBills: () => {
    let url = `${baseUrl}/micros/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroClosedBills: (date) => {
    let url = `${baseUrl}/micros/closed-bills`;

    if (date) {
      const formattedDate = encodeURIComponent(date.toISOString()); // Format the date and encode it
      url += `?date=${formattedDate}`;
    }

    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroSalesByWeek: () => {
    const url = `${baseUrl}/micros/closed-bills/weekly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroSalesByMonth: () => {
    const url = `${baseUrl}/micros/closed-bills/monthly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroBill: (billId) => {
    const url = `${baseUrl}/micros/bills/${billId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  toggleMicroOrderReady: (orderId) => {
    const url = `${baseUrl}/micros/orders/${orderId}/ready`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  microBillPaid: (billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/paid`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  microBillDelivered: (billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/delivered`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  createMicroProduct: (params) => {
    const url = `${baseUrl}/micros/products`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  updateMicroEstablishmentProduct: (productId, params) => {
    const url = `${baseUrl}/micros/products/${productId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  getMicroEstablishmentProducts: () => {
    const url = `${baseUrl}/micros/products`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroEstablishmentProduct: (productId) => {
    const url = `${baseUrl}/micros/products/${productId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroEstablishmentCategories: () => {
    const url = `${baseUrl}/micros/product_categories`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  toggleMicroProductAvailability: (productId) => {
    const url = `${baseUrl}/micros/products/${productId}/availability`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  // MANAGER
  getManagerProducts: () => {
    const url = `${baseUrl}/managers/products`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },
  
  toggleProductAvailability: (productId) => {
    const url = `${baseUrl}/managers/products/${productId}/availability`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },
  
  // getWaiters: () => {
  //   const url = `${baseUrl}/managers/waiters`
  //   const headers = authHelpers.getAuthHeaders();
  //   return axios.get(url, headers)
  // },
  
  // getTables: () => {
  //   const url = `${baseUrl}/managers/tables`
  //   const headers = authHelpers.getAuthHeaders();
  //   return axios.get(url, headers)
  // },
  
  // getManagerReports: (params) => {
  //   const url = `${baseUrl}/managers/reports/`
  //   const headers = authHelpers.getAuthHeaders();
  //   return axios.post(url, params, headers)
  // },

  // REFERRERS
  createReferrerOwner: (params) => {
    const url = `${baseUrl}/referrers/owners`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  getReferrerCommissions: () => {
    const url = `${baseUrl}/referrers/commissions`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  }
};

export default API;