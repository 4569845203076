const userKey =  'restauranterousr';
const tokenKey =     'restauranterotkn';
const userNameKey =  'restauranteroname';
const channelIdKey = 'restauranterochid';

const authHelpers = {
  login: (user, token, name, channel_id) => {
    if (user && token) {
      window.localStorage.setItem(userKey, user);
      window.localStorage.setItem(tokenKey, token);
      
      if (name) {
        window.localStorage.setItem(userNameKey, name);
      } else {
        window.localStorage.removeItem(userNameKey);
      }

      if (channel_id) {
        window.localStorage.setItem(channelIdKey, channel_id);
      } else {
        window.localStorage.removeItem(channelIdKey);
      }
    }
  },
  
  logout: () => {
    window.localStorage.removeItem(userKey);
    window.localStorage.removeItem(tokenKey);
    window.localStorage.removeItem(userNameKey);
    window.localStorage.removeItem(channelIdKey);
  },
  
  getUser: () => {
    return window.localStorage.getItem(userKey);
  },
  
  getToken: () => {
    return window.localStorage.getItem(tokenKey);
  },

  getUserName : () => {
    return window.localStorage.getItem(userNameKey);
  },

  getChannelId : () => {
    return window.localStorage.getItem(channelIdKey);
  },
  
  getAuthHeaders: () => {
    const token = authHelpers.getToken();
    return {
      headers: { 'Authorization': `Token ${token}` } 
    }
  }
}

export default authHelpers;