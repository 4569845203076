import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, List, Button, Icon } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import Header from 'components/Header';
import CancelGoButtons from 'components/CancelGoButtons';
import CashierMenu from './ui/CashierMenu';
import CashierBill from './ui/CashierBill';
import CashierBillAssigner from './ui/CashierBillAssigner';
import API from 'utils/api';

class CashierOrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bill: null,
      orders: [],
      showMenu: false,
      showBill: false,
      showAssigner: false
    };
  }

  showMenu = () => {
    this.setState({ 
      showMenu: true,
      showBill: false,
      showAssigner: false
    });
  }

  hideMenu = () => {
    this.setState({ showMenu: false });
  }

  showBill = () => {
    this.setState({
      showBill: true,
      showMenu: false,
      showAssigner: false
    });
  }

  hideBill = () => {
    this.setState({ showBill: false });
  }

  showAssigner = () => {
    this.setState({
      showAssigner: true,
      showBill: false,
      showMenu: false,
    });
  }

  hideAssigner = () => {
    this.setState({ showAssigner: false });
  }

  addOrder = (product) => {
    const newOrderItem = {
      uuid: uuid(),
      product: product,
      quantity: 1
    }
    
    this.setState((prevState) => {
      const newOrders = [newOrderItem, ...prevState.orders];

      return {
        orders: newOrders,
        showMenu: false
      }
    });
  }

  increaseOrderQuantity = (uuid) => {
    this.setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.uuid === uuid) {
          order['quantity'] += 1;
        }

        return order
      });

      return { orders: updatedOrders }
    });
  }

  decreaseOrderQuantityOrDelete = (uuid) => {
    this.setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.uuid === uuid) {
          const newQuantity = order['quantity'] - 1;
          
          if (newQuantity === 0) {
            return null
          }

          order['quantity'] -= 1;
        }

        return order
      }).filter(Boolean);

      return { orders: updatedOrders }
    });
  }

  cancelOrder = () => {
    this.setState({
      bill: null,
      orders: [],
      showBill: false,
      showMenu: false,
      showAssigner: false
    });
  }

  createOrderBill = () => {
    const orderParams = this.state.orders.map((order) => {
      order.product_id = order.product.id

      return {
        product_id: order.product.id,
        quantity: order.quantity
      }
    })
    
    const params = {
      orders: orderParams
    };
    
    API.createCashierBill(params)
      .then((response) => {
        this.setState({
          bill: response.data
        }, this.showAssigner);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  assignBill = (orderTokenId) => {
    const params = {
      note: orderTokenId
    };
    
    API.assignCashierBill(this.state.bill.id, params)
      .then((response) => {
        this.setState({
          bill: null,
          orders: [],
          showMenu: false,
          showBill: false,
          showAssigner: false
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {

    // when assigning order token to bill
    if (this.state.showAssigner) {
      return (
        <CashierBillAssigner
          bill={this.state.bill}
          onAssignFn={this.assignBill}
        />
      )
    }

    // when paying the bill
    if (this.state.showBill) {
      return (
        <CashierBill
          orders={this.state.orders}
          cancelFn={this.hideBill}
          onConfirmFn={this.createOrderBill}
        />
      )
    }

    // when adding a new order
    if (this.state.showMenu) {
      return (
        <CashierMenu
          cancelFn={this.hideMenu}
          addOrderFn={this.addOrder}
        />
      )
    }

    // when there are no orders yet
    if (this.state.orders.length === 0) {
      return (
        <div>
          <Header text="Cuenta nueva" subtitle="Agrega una orden para continuar">
            <Button circular icon primary onClick={this.showMenu}>
              <Icon name="plus" />
            </Button>
          </Header>
        </div>
      )
    }

    // when there are orders in the bill
    return (
      <div>
        <Header text="Cuenta en proceso" subtitle="Ordenes">
          <Button circular icon primary onClick={this.showMenu}>
            <Icon name="plus" />
          </Button>
        </Header>
        <List divided>
          { this.state.orders.map((order) =>
            <List.Item style={{ padding: '1em 0' }} key={order.uuid}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={11} verticalAlign="middle">
                    <p style={{ marginBottom: 0 }}>
                      <b style={{ marginRight: '0.5em', fontSize: '20px' }}>
                        { order.quantity }
                      </b>
                      <span style={{ fontSize: '18px' }}>
                        { order.product.name }
                      </span>
                    </p>
                    { (this.state.orderBeingEditedUUID !== order.uuid) &&
                      <p style={{ fontWeight: 'normal', color: 'gray' }}>
                        { order.notes }
                      </p>
                    }
                  </Grid.Column>
                  
                  <Grid.Column width={5} verticalAlign="middle" textAlign="right">
                    <div>
                      <Button icon circular color={order.quantity === 1 ? 'red' : 'grey'} onClick={ () => this.decreaseOrderQuantityOrDelete(order.uuid) }>
                        <Icon name="minus" />
                      </Button>
                      <Button icon circular color="grey" onClick={ () => this.increaseOrderQuantity(order.uuid) }>
                        <Icon name="plus" />
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Item>
          )}
        </List>

        <CancelGoButtons
          cancelFn={this.cancelOrder}
          goText="cobrar orden"
          goFn={this.showBill}
          goEnabled={true}
        />
      </div>
    )
  }
}

export default withRouter(CashierOrdersPage);