import React from 'react';
import { Card, Button, Icon, Statistic } from 'semantic-ui-react';
import API from 'utils/api';
import Header from 'components/Header';
import OwnerForm from './commissions/OwnerForm';
import formatter from 'utils/formatter';

// import Confirmation from 'components/Confirmation';
// import BillForm from './bills/BillForm';
// import OpenBills from './bills/OpenBills';

class ReferrerComissionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commissions: [],
      total: 0,
      showOwnerForm: false
    };
  }
  
  componentDidMount() {
    this.getCommissions();
  }

  getCommissions = () => {
    API.getReferrerCommissions()
      .then((response) => {
        const commissions = response.data;
        let total = 0.0;

        commissions.forEach(commission => {
          // total += commission.price
        });

        this.setState({ commissions });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createOwner = (name, phoneNumber) => {
    const params = {
      name: name,
      phone_number: phoneNumber
    };

    API.createReferrerOwner(params)
      .then((response) => {
        this.setState({ showOwnerForm: false }, this.getCommissions);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {

    // when creating a new owner
    if (this.state.showOwnerForm) {
      return (
        <OwnerForm
          cancelFn={ () => this.setState({ showOwnerForm: false }) }
          submitFn={this.createOwner}
        />
      )
    }

    // when editing a selected bill
    // if (this.state.editBill) {
    //   return (
    //     <BillForm
    //       tables={this.state.tables}
    //       cancelFn={ () => this.setState({ editBill: null }) }
    //       submitFn={this.updateBill}
    //       bill={this.state.editBill}
    //     />
    //   )
    // }

    // when deleting a bill without orders
    // if (this.state.deleteBill) {
    //   const bill = this.state.deleteBill;
      
    //   const headerText="Eliminar cuenta vacía";
    //   const bodyText = `Confirma que quieres eliminar la cuenta #${bill.id} (${bill.table.name})`;
    //   const confirmText = "Eliminar cuenta";
    //   const valid = bill.total === 0;
      
    //   return (
    //     <Confirmation
    //       headerText={headerText}
    //       bodyText={bodyText}
    //       cancelFn={ () => this.setState({ deleteBill: null }) }
    //       confirmFn={ () => this.deleteBill(bill) }
    //       confirmText={confirmText}
    //       enabled={valid}
    //       warning={true}
    //     />
    //   )
    // }

    // default
    return (
      <div>
        <Header text="Comisiones" subtitle="">
          <Button circular icon primary onClick={ () => this.setState({ showOwnerForm: true }) }>
            <Icon name="plus" />
          </Button>
        </Header>

        <Statistic.Group>
          <Statistic>
            <Statistic.Value>
              <span>
                { formatter.currency(this.state.total) }
              </span>
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>

        <div style={{ marginTop: '2em', paddingBottom: '2em' }}>
          { this.state.commissions.map((commission) => 
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  { commission.owner.name }
                </Card.Header>
              </Card.Content>
              
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default ReferrerComissionsPage;