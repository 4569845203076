import React from 'react';
import Pusher from 'pusher-js';
import { withRouter } from 'react-router-dom';
import authHelpers from 'utils/authHelpers';
import API from 'utils/api';
import Header from 'components/Header';
import Confirmation from 'components/Confirmation';
import ChefBill from './ui/ChefBill';

class ChefOrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: []
    };
  }
  
  componentDidMount() {
    this.getOrders();
    
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      Pusher.logToConsole = true;
    }
    
    this.subscribeToPusherEvents();
  }

  subscribeToPusherEvents = () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true
    });
    
    const channelName = authHelpers.getChannelId();
    const channel = pusher.subscribe(channelName);
    
    channel.bind('new-orders', (data) => {
      this.getOrders();
    });
  }

  getOrders = () => {
    API.getChefOrders()
      .then((response) => {
        this.setState({ bills: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleOrderReady = (orderId) => {
    API.toggleChefOrderReady(orderId)
      .then((response) => {
        // window.location.reload();
        this.getOrders();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deliverBill = (billId) => {
    API.deliverChefBill(billId)
      .then((response) => {
        this.getOrders();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {
    // when there are pending orders
    let headerText = 'Sin pedidos pendientes';

    // when there are no pending orders
    if (this.state.bills.length === 0) {
      return (
        <div>
          <Header text={headerText} />
        </div>
      )
    }

    // when an order is being delivered
    // if (this.state.selectedOrder) {
    //   const order = this.state.selectedOrder;
    //   const text = `Confirma la entrega de la orden ${order.product.name} a ${order.waiter.name} para ${order.table.name}.`;
      
    //   return (
    //     <Confirmation
    //       headerText="Entregar orden terminada"
    //       bodyText={text}
    //       cancelFn={ () => this.setState({ selectedOrder: null }) }
    //       confirmFn={ () => this.deliverOrder(this.state.selectedOrder) }
    //       confirmText="Entregar orden"
    //       warning={true}
    //       enabled={true}
    //     />
    //   )
    // }

    if (this.state.bills.length === 1) {
      headerText = '1 pedido pendiente';
    }
    
    if (this.state.bills.length > 1) {
      headerText = `${this.state.bills.length} pedidos pendientes`;
    }
    
    return (
      <div>
        <Header text={headerText} />
        
        <div>
          { this.state.bills.map((orders, index) =>
            <ChefBill
              key={index}
              orders={orders}
              toggleOrderReadyFn={ (orderId) => this.toggleOrderReady(orderId) }
              deliverBillFn={ (billId) => this.deliverBill(billId) }
            />
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(ChefOrdersPage);