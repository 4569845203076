import React from 'react';
import Confirmation from 'components/Confirmation';
import BillForm from './ui/BillForm';
import OpenBills from './ui/OpenBills';
import API from 'utils/api';

class WaiterBillsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: [],
      newBill: false,
      editBill: null,
      deleteBill: null
    };
  }
  
  componentDidMount() {
    this.getBills();
  }

  getBills = () => {
    API.getWaiterBills()
      .then((response) => {
        this.setState({ bills: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  newBill = () => {
    this.setState({ newBill: true });
  }

  editBill = (bill) => {
    this.setState({ editBill: bill });
  }

  createBill = (tableId, note) => {
    const params = {
      table_id: tableId,
      note: note
    };
    
    API.createWaiterBill(params)
      .then((response) => {
        this.setState({ newBill: false }, this.getBills);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateBill = (bill, tableId, note) => {
    const params = {
      table_id: tableId,
      note: note
    };
    
    API.updateWaiterBill(bill.id, params)
      .then((response) => {
        this.setState({ 
          newBill: false,
          editBill: null,
          deleteBill: null
        }, this.getBills);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteBill = (bill) => {
    API.deleteWaiterBill(bill.id)
      .then((response) => {
        this.setState({
          newBill: false,
          editBill: null,
          deleteBill: null
        }, this.getBills);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {

    // when creating a new bill
    if (this.state.newBill) {
      return (
        <BillForm
          tables={this.state.tables}
          cancelFn={ () => this.setState({ newBill: false }) }
          submitFn={this.createBill}
        />
      )
    }

    // when editing a selected bill
    if (this.state.editBill) {
      return (
        <BillForm
          tables={this.state.tables}
          cancelFn={ () => this.setState({ editBill: null }) }
          submitFn={this.updateBill}
          bill={this.state.editBill}
        />
      )
    }

    // when deleting a bill without orders
    if (this.state.deleteBill) {
      const bill = this.state.deleteBill;
      
      const headerText="Eliminar cuenta vacía";
      const bodyText = `Confirma que quieres eliminar la cuenta #${bill.id}`;
      const confirmText = "Eliminar cuenta";
      const valid = bill.total === 0;
      
      return (
        <Confirmation
          headerText={headerText}
          bodyText={bodyText}
          cancelFn={ () => this.setState({ deleteBill: null }) }
          confirmFn={ () => this.deleteBill(bill) }
          confirmText={confirmText}
          enabled={valid}
          warning={true}
        />
      )
    }

    // when there are active bills
    return (
      <OpenBills
        bills={this.state.bills}
        newBillFn={this.newBill}
        editBillFn={this.editBill}
        deleteBillFn={ (bill) => this.setState({ deleteBill: bill }) }
      />
    );
  }
}

export default WaiterBillsPage;