import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Statistic } from 'semantic-ui-react';
import Header from 'components/Header';
import DancerOrderCard from './ui/DancerOrderCard';
import API from 'utils/api';
import formatter from 'utils/formatter';

class DancerPaidOrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      total: 0
    };
  }
  
  componentDidMount() {
    this.getOrders();
  }

  getOrders = () => {
    API.getDancerOrders(true)
      .then((response) => {
        const orders = response.data;
        let total = 0;

        orders.forEach((order) => {
          total += order.commission;
        });

        this.setState({ orders, total });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    
    // when there are no pending orders
    if (this.state.orders.length === 0) {
      return (
        <div>
          <Header text="Sin comisiones pagadas" />
        </div>
      )
    }

    let headerText = `${this.state.orders.length} comisiones pagadas`;

    if (this.state.orders.length === 1) {
      headerText = `${this.state.orders.length} comision pagada`;
    }
    
    return (
      <div>
        <Header text={headerText} />

        <Card fluid color="black">
          <Card.Content textAlign="center">
            <Statistic>
              <Statistic.Label>
                total comisiones pagadas
              </Statistic.Label>
              <Statistic.Value>
                <span>
                  { formatter.currency(this.state.total) }
                </span>
              </Statistic.Value>
            </Statistic>
          </Card.Content>
        </Card>
        
        <div>
          { this.state.orders.map(order =>
            <DancerOrderCard order={order} key={order.id} paid />
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(DancerPaidOrdersPage);