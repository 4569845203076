import React from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Grid, List, Button, Icon, Form, Input } from 'semantic-ui-react';
import Header from 'components/Header';
import Message from 'components/Message';
import CancelGoButtons from 'components/CancelGoButtons';
import MicroMenu from './ui/MicroMenu';
import API from 'utils/api';
import formatter from 'utils/formatter';

class MicroNewBillPage extends React.Component {
  constructor(props) {
    super(props);
    this.noteInputRef = React.createRef();
    this.state = {
      orders: [],
      note: '',
      total: 0,
      showNoteForm: true,
      showMenu: false,
      showConfirmation: false
    };
  }

  componentDidMount() {
    this.noteInputRef.current.focus();
  }

  handleNote = (event) => {
    this.setState({ note: event.target.value });
  }

  openBill = () => {
    this.setState({
      showNoteForm: false,
      showMenu: true
    });
  }

  updateTotal = () => {
    let total = 0.0;
    
    this.state.orders.forEach((order) => {
      const orderTotal = order.product.price * order.quantity;
      total += orderTotal;
    });

    this.setState({ total });
  }

  addOrder = (product) => {
    const newOrderItem = {
      uuid: uuid(),
      product: product,
      quantity: 1
    }
    
    this.setState((prevState) => {
      const newOrders = [newOrderItem, ...prevState.orders];

      newOrders.forEach((order) => {
        console.log(order);
      })

      return {
        orders: newOrders,
        showMenu: false
      }
    }, this.updateTotal);
  }

  increaseOrderQuantity = (uuid) => {
    this.setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.uuid === uuid) {
          order['quantity'] += 1;
        }

        return order
      });

      return { orders: updatedOrders }
    }, this.updateTotal);
  }

  decreaseOrderQuantityOrDelete = (uuid) => {
    this.setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.uuid === uuid) {
          const newQuantity = order['quantity'] - 1;
          
          if (newQuantity === 0) {
            return null
          }

          order['quantity'] -= 1;
        }

        return order
      }).filter(Boolean);

      return { orders: updatedOrders }
    }, this.updateTotal);
  }

  validateBill = () => {
    let valid = true;

    if (this.state.note === '') {
      valid = false;
    }

    if (this.state.orders.length === 0) {
      valid = false;
    }

    return valid
  }

  createBill = () => {
    const orderParams = this.state.orders.reverse().map((order) => {
      return {
        product_id: order.product.id,
        quantity: order.quantity
      }
    })
    
    const params = {
      orders: orderParams,
      note: this.state.note
    };

    API.createMicroBill(params)
      .then((response) => {
        this.props.history.push('/micro/bills');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {

    // Get the bill's ID (customer name) (default)
    if (this.state.showNoteForm) {
      return (
        <div>
          <Header text="Cuenta nueva" subtitle="Agrega el nombre del cliente" />
          
          <Form as="div" onSubmit={ (e) => e.preventDefault() }>
            <Form.Field>
              <Input
                ref={this.noteInputRef}
                value={this.state.note}
                onChange={this.handleNote}
                placeholder="Ejemplo: Alex"
                style={{ fontSize: '18px' }}
              />
            </Form.Field>
            
            <CancelGoButtons
              cancelFn={ () => this.props.history.push('/micro/bills') }
              goText="abir cuenta"
              goFn={this.openBill}
              goEnabled={this.state.note.length > 0}
            />
          </Form>
        </div>
      )
    }

    // Display menu
    if (this.state.showMenu) {
      return (
        <MicroMenu
          cancelFn={ () => this.setState({ showMenu: false }) }
          addOrderFn={this.addOrder}
        />
      )
    }

    // Display Confirmation before create
    if (this.state.showConfirmation) {
      const formattedTotal = formatter.currency(this.state.total);;
      return (
        <div style={{ marginTop: '1em' }}>
          <Message headerText="confirmar nueva cuenta">
            <p style={{ textAlign: 'center', fontSize: '16px' }}>
              Confirma una nueva cuenta
              <br/>
              para <b>{this.state.note}</b>
              <br/>
              por <b>{formattedTotal}</b>
            </p>
  
            <CancelGoButtons
              cancelFn={ () => this.setState({ showConfirmation: false })}
              goText="crear cuenta nueva"
              goEnabled={true}
              goFn={this.createBill}
              warning={true}
            />
          </Message>
        </div>
      )
    }

    // Display Bill
    return (
      <div>
        <Header text="Cuenta nueva" subtitle="Agrega las ordenes del cliente">
          <Button circular icon primary onClick={ () => this.setState({ showMenu: true }) }>
            <Icon name="plus" />
          </Button>
        </Header>
        <List divided>
          { this.state.orders.map((order) =>
            <List.Item style={{ padding: '1em 0' }} key={order.uuid}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={11} verticalAlign="middle">
                    <p style={{ marginBottom: 0 }}>
                      <b style={{ marginRight: '0.5em', fontSize: '20px' }}>
                        { order.quantity }
                      </b>
                      <span style={{ fontSize: '18px' }}>
                        { order.product.name }
                      </span>
                    </p>
                    { (this.state.orderBeingEditedUUID !== order.uuid) &&
                      <p style={{ fontWeight: 'normal', color: 'gray' }}>
                        { order.notes }
                      </p>
                    }
                  </Grid.Column>
                  
                  <Grid.Column width={5} verticalAlign="middle" textAlign="right">
                    <div>
                      <Button icon circular color={order.quantity === 1 ? 'red' : 'grey'} onClick={ () => this.decreaseOrderQuantityOrDelete(order.uuid) }>
                        <Icon name="minus" />
                      </Button>
                      <Button icon circular color="grey" onClick={ () => this.increaseOrderQuantity(order.uuid) }>
                        <Icon name="plus" />
                      </Button>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Item>
          )}
        </List>
        
        <CancelGoButtons
          cancelFn={ () => this.props.history.push('/micro/bills') }
          goText="crear orden"
          goEnabled={this.validateBill()}
          goFn={ () => this.setState({ showConfirmation: true }) }
        />
      </div>
    )
  }
}

export default withRouter(MicroNewBillPage);