import React, { useState, useEffect, useRef } from 'react';
import Header from 'components/Header';
import { Form, FormField, Button } from 'semantic-ui-react';
import CodeWriter from './CodeWriter';

export default function CashierBillAssigner({ onAssignFn }) {
  const [orderTokenId, setOrderTokenId] = useState('');
  const [valid, setValid] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleOrderTokenIdInput = (event) => {
    const input = event.target.value;
    setOrderTokenId(input);

    if (input !== '') {
      setValid(true);
    } else {
      setValid(false);    
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onAssignFn(orderTokenId)
  }
  
  return (
    <div>
      <Header text="Cuenta pagada" subtitle="Asignar cuenta al cliente" />

      <Form onSubmit={handleSubmit}>
        <FormField>
          <label>Identificador</label>
          <input
            ref={inputRef}
            placeholder='Ejemplo: "42" o "Marvin"'
            value={orderTokenId}
            onChange={handleOrderTokenIdInput}
          />
        </FormField>
        
        <Button type='submit' primary={valid} disabled={!valid} fluid>
          asignar ticket
        </Button>
      </Form>
    </div>
  )
  
  
  return (
    <CodeWriter />
  )
}