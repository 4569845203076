import React from 'react';
import { Image, Card, Divider } from 'semantic-ui-react';
import Header from 'components/Header';
import LogoHero from 'ui/LogoHero';
import businesOwnerImage from 'images/business-owner.png';
import OwnerTotalSalesReportDemo from 'images/owner-total-sales-reports.gif';
import OwnerEstablishmentSalesReportDemo from 'images/owner-establishment-sales-reports.gif';
import WaitersDemo from 'images/waiters.gif';
import ChefsDemo from 'images/chefs.gif';
import PublicMenuDemo from 'images/public-menu.gif';
import MangerToggleProductAvailabilityDemo from 'images/manager-toggle-product-availabliity.gif'

class HomePage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center', paddingBottom: '2em', maxWidth: '400px', margin: '0 auto' }}>
        <div style={{ marginBottom: '2em' }}>
          <Image src={businesOwnerImage} fluid />
          <div style={{ marginTop: '3em', marginBottom: '5em' }}>
            <h1 style={{ marginBottom: '2.5px' }}>
              restaurantero.io
            </h1>
            <p style={{ color: 'gray' }}>
              más control de tus negocios<br/>
              cuando quieras y donde quieras
            </p>
          </div>
        </div>

        <Divider style={{ margin: '2em 4em' }} />

        <div>
          <p>
            restaurantero es un <br />
            <a>sistema de aplicaciones web</a> <br/>
            que te da en tiempo real <br/>
            la información más relevante <br/>
            de tus negocios <br/>
            dondequiera que estés
          </p>
        </div>

        <Divider style={{ margin: '2em 4em' }} />
        
        <div style={{ marginTop: '2em' }}>
          <h2>ventas totales</h2>
          
          <p style={{ padding: '0 3.5em', color: 'gray' }}>
            como dueño de negocios <br />
            quieres saber cuánto estás vendiendo <br />
            en cualquier momento <br />
            sin importar en dónde estás
          </p>

          <div style={{ padding: '0 3em', margin: '1.5em 0' }}>
            <Card fluid>
              <Image src={OwnerTotalSalesReportDemo} />
            </Card>
          </div>

          <p>
            con restaurantero <br/>
            puedes ver todas las ventas <br/>
            de todos tus restaurantes <br/>
            en un solo lugar <br/>
            y en tiempo real <br/>
          </p>
        </div>

        <Divider style={{ margin: '2em 4em' }} />

        <div style={{ marginTop: '2em' }}>
          <h2>ventas específicas</h2>
          
          <p style={{ padding: '0 3.5em', color: 'gray' }}>
            si necesitas más detalles <br />
            de cada tienda <br />
            o hasta de cada venta
          </p>

          <div style={{ padding: '0 3em', margin: '1.5em 0' }}>
            <Card fluid>
              <Image src={OwnerEstablishmentSalesReportDemo} />
            </Card>
          </div>

          <p>
            con restaurantero <br/>
            puedes ver cada establecimiento <br />
            y todos los detalles de sus ventas <br />
            en unos cuantos clicks <br/>
            a medida que van sucediendo
          </p>
        </div>

        <Divider style={{ margin: '2em 4em' }} />
        
        <div>
          <h3>
            ¿cómo funciona?
          </h3>
          <p style={{ color: 'gray' }}>
            restaurantero digitaliza completamente <br/>
            el sistema de comandas en tus restaurantes <br/>
            centralizando toda la información en la nube <br/>
            para que la tengas siempre disponible
          </p>
        </div>

        <Divider style={{ margin: '2em 4em' }} />

        <div style={{ marginTop: '2em' }}>
          <h2>meseros y cocinas</h2>
          
          <p style={{ padding: '0 3.5em', color: 'gray' }}>
            tus meseros y cocinas pueden manejar <br />
            cuentas y ordenes de forma<br />
            sencilla y eficiente
          </p>

          <div style={{ padding: '0 3em', margin: '1.5em 0' }}>
            <Card fluid>
              <Image src={WaitersDemo} />
            </Card>
          </div>

          <p style={{ padding: '0 3.5em', color: 'gray' }}>
            todo se maneja en la nube <br />
            en tiempo real en el restaurante <br/>
          </p>

          <div style={{ padding: '0 3em', margin: '1.5em 0' }}>
            <Card fluid>
              <Image src={ChefsDemo} />
            </Card>
          </div>

          <p>
            y en tiemp real para ti <br/>
            ya que en cuanto una cuenta se paga <br/>
            tú recibes un reporte de esa venta <br />
            para cuando quieras verla
          </p>
        </div>

        <Divider style={{ margin: '2em 4em' }} />

        <div style={{ marginTop: '2em' }}>
          <h2>menú inteligente</h2>
          
          <p style={{ padding: '0 3.5em', color: 'gray' }}>
            el menú que utilizan tus meseros <br/>
            también está disponible para tus clientes <br/>
            a través de un código QR que te daremos
          </p>

          <div style={{ padding: '0 3em', margin: '1.5em 0' }}>
            <Card fluid>
              <Image src={PublicMenuDemo} />
            </Card>
          </div>

          <p>
            este menú se sincroniza <br/>
            con tu inventario <br/>
            a fin de tenerlo siempre actualizado <br/>
          </p>

          <div style={{ padding: '0 3em', margin: '1.5em 0' }}>
            <Card fluid>
              <Image src={MangerToggleProductAvailabilityDemo} />
            </Card>
          </div>
        </div>

        <div>
          <h3>
            ¿listo para probarlo?
          </h3>
          <p style={{ color: 'gray' }}>
            comienza a tener más control <br/>
            de tus negocios con restaurantero
          </p>
        </div>
      </div>
    )
  }
}

export default HomePage;