import React, { useState, useEffect } from 'react';
import { Card, List, Button, Icon, Input } from 'semantic-ui-react';
import Header from 'components/Header';
import API from 'utils/api';

export default function MicroMenu({ cancelFn, addOrderFn }) {
  const [menu, setMenu] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState(menu);
  const [input, setInput] = useState('');

  // load the updated menu
  useEffect(() => {
    API.getMicroEstablishmentProducts()
      .then((response) => {
        setMenu(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // filter menu when input changes
  useEffect(() => {
    const regex = new RegExp(input, 'i');
    const newFilteredMenu = menu.map((categoryProducts) => {
      const category = categoryProducts[0];
      
      const products = categoryProducts[1].filter((product) => {
        return regex.test(product.name)
      });

      if (products.length === 0) {
        return null
      }

      return [category, products]
    });
    const newCleanedUpMenu = newFilteredMenu.filter((item) => item !== null);
    setFilteredMenu(newCleanedUpMenu);
  }, [input, menu])
  
  return (
    <div>
      <Header text="Menu" subtitle="Agrega un producto">
        <Button circular icon onClick={cancelFn}>
          <Icon name="cancel" />
        </Button>
      </Header>

      <div>
        <Input
          fluid
          placeholder="buscar..." 
          onChange={ (e) => setInput(e.target.value) }
          value={input}
        />
        
        { (filteredMenu.length > 0) &&
          <Card fluid>
            <Card.Content>
              <List divided>
                { filteredMenu.map((categoryProducts) =>
                  <List.Item
                    key={categoryProducts[0]}
                  >
                    <List.Content style={{ margin: '1em 0' }}>
                      <List.Header>
                        { categoryProducts[0] }
                      </List.Header>
                      <List.List>
                        { categoryProducts[1].map((product) => {
                          if (product.out_of_stock) {
                            return (
                              <List.Item
                                key={product.id}
                                style={{ padding: '0.5em 0' }}
                              >
                                <List.Content>
                                  <List.Description>
                                    <span style={{ textDecoration: 'line-through' }}>
                                      { product.name }
                                    </span>
                                  </List.Description>
                                </List.Content>
                              </List.Item>
                            )
                          }
                          
                          return (
                            <List.Item
                              key={product.id}
                              style={{ cursor: 'pointer', padding: '0.5em 0' }}
                              onClick={ () => addOrderFn(product) }
                            >
                              <List.Content>
                                <List.Description>{ product.name }</List.Description>
                              </List.Content>
                            </List.Item>
                          )
                        })}
                      </List.List>
                    </List.Content>
                  </List.Item>
                )}
              </List>
            </Card.Content>
          </Card>
        }
      </div>
    </div>
  )
}