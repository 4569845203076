import React from 'react';
import { Card, Statistic } from 'semantic-ui-react';
import Header from 'components/Header';
import { format, parse } from 'date-fns';
import { es } from 'date-fns/locale';
import API from 'utils/api';
import formatter from 'utils/formatter';

class MicroSalesByMonthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: [],
      total: 0.0
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    API.getMicroSalesByMonth()
      .then((response) => {
        const sales = response.data;
        
        // calculate total
        let total = 0;

        sales.forEach((sale) => {
          total += sale.total;
        });

        this.setState({ sales, total });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <div>
        <Header text="Reporte de ventas por mes" subtitle={new Date().getFullYear()} />
        
        <div>
          <Statistic size="small">
            <Statistic.Value>{ formatter.currency(this.state.total) }</Statistic.Value>
            <Statistic.Label>Total</Statistic.Label>
          </Statistic>

          { this.state.sales.map((sale) => {
            return (
              <Card fluid key={sale.week}>
                <Card.Content>
                  <Card.Header>
                    <span style={{ color: 'darkgray' }}>
                    { format(parse(sale.month, 'MMMM', new Date()), "MMMM yyyy", { locale: es }) }
                    </span>
                  </Card.Header>
                  <Card.Description>
                    <Statistic.Group size="tiny">
                      <Statistic>
                        <Statistic.Value>{ formatter.currency(sale.total) }</Statistic.Value>
                      </Statistic>
                    </Statistic.Group>
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MicroSalesByMonthPage;