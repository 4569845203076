import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Header from 'components/Header';
import jsQR from 'jsqr';

class WaiterDanceTokenScanner extends React.Component {  
  constructor(props) {
    super(props);
    
    this.canvasRef = React.createRef();
    this.videoRef = React.createRef();
  }
  
  componentDidMount() {
    this.getVideoStream();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getVideoStream();
    }
  }
  
  getVideoStream = () => {
    if (
      window.navigator.mediaDevices &&
      window.navigator.mediaDevices.getUserMedia
    ) {
      const UserMediaConstraints = {
        audio: false,
        video: { facingMode: "environment" }
      };
      
      window.navigator.mediaDevices.getUserMedia(UserMediaConstraints)
        .then((stream) => {
          this.videoRef.current.srcObject = stream;
          this.videoRef.current.play();
          requestAnimationFrame(this.tick)
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
  
  tick = () => {
    const video = this.videoRef.current;
    const canvas = this.canvasRef.current;
    
    canvas.height = video.height;
    canvas.width = video.width;
    
    const canvasCtx = canvas.getContext('2d');
    
    canvasCtx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvasCtx.getImageData(0, 0, canvas.width, canvas.height);
    
    const opts = { inversionAttempts: 'dontInvert' };
    const code = jsQR(imageData.data, imageData.width, imageData.height, opts);
    
    if (code) {
      this.closeCamera();
      this.props.onSuccessfulScanFn(code.data);
    }
    else {
      requestAnimationFrame(this.tick);
    }
  }

  closeCamera = () => {
    const stream = this.videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(track => {
      track.stop();
    });

    this.videoRef.current.srcObject = null;
  }
  
  render() {
    return (
      <div>
        <Header text="Assignar orden" subtitle={this.props.order.product.name} />
        
        <div>
          <canvas ref={this.canvasRef} hidden />
          <video
            ref={this.videoRef}
            width="300px"
            height="300px"
            autoPlay
            playsInline
            muted
          />
        </div>
      </div>
    );
  }
}


export default withRouter(WaiterDanceTokenScanner)