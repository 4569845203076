import React, { useState, useEffect } from 'react';
import { List, Grid, Form, Card, Input } from 'semantic-ui-react';
import formatter from 'utils/formatter';
import Header from 'components/Header';
import CancelGoButtons from 'components/CancelGoButtons';
import API from 'utils/api';

export default function CashierBill({ orders, cancelFn, onConfirmFn }) {
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [reversedOrders, setReversedOrders] = useState([]);
  
  const calculateTotalPrice = () => {
    let total = 0.0;
    
    orders.forEach((order) => {
      const orderPrice = order.product.price * order.quantity;
      total += orderPrice;
    });

    return total
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
    setReversedOrders(orders.reverse());
  }, []);

  return (
    <div>
      <Header text="Cuenta para pagar" subtitle="Cobrar total de la cuenta" />
      
      <Card fluid>
        <Card.Content>
          <List divided relaxed>
            { reversedOrders.map((order) => 
              <List.Item key={order.uuid}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12} verticalAlign="top">
                      <p style={{ fontSize: '1em', marginBottom: 0 }}>
                        { order.product.name }
                      </p>
                      { (order.quantity > 1) &&
                        <p style={{ fontSize: '1em', marginBottom: 0 }}>
                          x {order.quantity}
                        </p>
                      }
                    </Grid.Column>
                    
                    <Grid.Column width={4} verticalAlign="top" textAlign="right">
                      <p style={{ fontSize: '1em', marginBottom: 0, color: (order.quantity > 1 ? 'gray' : '') }}>
                        { formatter.currency(order.product.price) }
                      </p>
                      { (order.quantity > 1) &&
                        <p style={{ fontSize: '1em', marginBottom: 0 }}>
                          {formatter.currency((order.quantity * order.product.price))}
                        </p>
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Item>
            )}
          </List>
        </Card.Content>
      </Card>
      
      <Card fluid color="blue">
        <Card.Header>
          <List relaxed>
            <List.Item>
              <div style={{ textAlign: 'right', padding: '1em', fontSize: 'large' }}>
                Total a pagar
                <span style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                { formatter.currency(totalPrice) }
                </span>
              </div>
            </List.Item>
          </List>
        </Card.Header>
      </Card>
      
      <CancelGoButtons
        cancelFn={cancelFn}
        goText="confirmar cuenta pagada"
        goFn={ () => onConfirmFn() }
        goEnabled={true}
      />
    </div>
  )
}