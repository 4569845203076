import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import authHelpers from 'utils/authHelpers';
import Nav from 'ui/Nav';
import PoweredBy from 'ui/PoweredBy';

// Micro
export const MicroRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  const user = authHelpers.getUser();
  
  if (token) {
    if (user === 'micro') {
      const component = React.cloneElement(children);
      
      // Add links here with { name, url }
      const links = [
        { name: 'cuentas abiertas', url: '/micro/bills' },
        { name: 'cuentas cerradas', url: '/micro/closed-bills' },
        { name: 'ventas por semana', url: '/micro/closed-bills/weekly' },
        { name: 'ventas por mes', url: '/micro/closed-bills/monthly' },
        { name: 'menu', url: '/micro/products' }
      ]; 

      const contentStyle = {
        flexGrow: 1
      };
  
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}

// Cashier
export const CashierRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  const user = authHelpers.getUser();
  
  if (token) {
    if (user === 'cashier') {
      const component = React.cloneElement(children);
      
      // Add links here with { name, url }
      const links = [
        // { name: 'cuentas', url: '/waiter/bills' }
      ]; 

      const contentStyle = {
        flexGrow: 1
      };
  
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}

// Waiter
export const WaiterRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  const user = authHelpers.getUser();
  
  if (token) {
    if (user === 'waiter') {
      const component = React.cloneElement(children);
      
      // Add links here with { name, url }
      const links = [
        // { name: 'cuentas', url: '/waiter/bills' }
      ]; 

      const contentStyle = {
        flexGrow: 1
      };
  
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}

// Dancer
export const DancerRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  const user = authHelpers.getUser();
  
  if (token) {
    if (user === 'dancer') {
      const component = React.cloneElement(children);
      
      // Add links here with { name, url }
      const links = [
        { name: 'comisiones pendientes', url: '/dancer/orders' },
        { name: 'comisiones pagadas', url: '/dancer/paid-orders' }
      ]; 

      const contentStyle = {
        flexGrow: 1
      };
  
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}

// Chef
export const ChefRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  const user = authHelpers.getUser();
  
  if (token) {
    if (user === 'chef') {
      const component = React.cloneElement(children);
      
      // Add links here with { name, url }
      const links = [
        { name: 'órdenes', url: '/chef/orders' }
      ]; 

      const contentStyle = {
        flexGrow: 1
      };
  
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}

// Owner
export const OwnerRoute = ({ children, ...remainingProps }) => {
  const user = authHelpers.getUser();
  const token = authHelpers.getToken();
  
  if (token) {
    if (user === 'owner') {
      const component = React.cloneElement(children);
      
      const links = [
        { name: 'ventas totales', url: '/owner/reports/sales' },
      ];
      
      const contentStyle = {
        flexGrow: 1
      };
    
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}

// Manager
export const ManagerRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  const user = authHelpers.getUser();
  
  if (token) {
    if (user === 'manager') {
      const component = React.cloneElement(children);
      
      const links = [
        // { name: 'menu',  url: 'products' },
      ];
      
      const contentStyle = {
        flexGrow: 1
      };
    
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}


// Referrer
export const ReferrerRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  const user = authHelpers.getUser();
  
  if (token) {
    if (user === 'referrer') {
      const component = React.cloneElement(children);
      
      const links = [
        // { name: 'menu',  url: 'products' },
      ];
      
      const contentStyle = {
        flexGrow: 1
      };
    
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}