import React from 'react';
import { List, Card, Grid, Icon } from 'semantic-ui-react';
import API from 'utils/api';
import Header from 'components/Header';

class ManagerProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    };
  }
  
  componentDidMount() {
    this.getProducts();
  }
  
  getProducts = () => {
    API.getManagerProducts()
      .then((response) => {
        this.setState({
          menu: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  toggleProductAvailability = (product) => {
    API.toggleProductAvailability(product.id)
      .then((response) => {
        this.getProducts();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    if (this.state.menu.length === 0) { return null }

    let totalAvailableProducts = 0;
    let totalProductsOutOfStock = 0;
    let headerText = 'Sin productos disponibles';
    let subtitle = '';

    this.state.menu.forEach((item) => {
      const category = item[0];
      const products = item[1];

      products.forEach((product) => {
        if (product.out_of_stock) {
          totalProductsOutOfStock += 1;
        } else {
          totalAvailableProducts += 1;
        }
      });
    });

    if (totalAvailableProducts === 1) {
      headerText = '1 producto disponible';
    }

    if (totalAvailableProducts > 1) {
      headerText = `${totalAvailableProducts} productos disponibles`;
    }

    if (totalProductsOutOfStock === 1) {
      subtitle = '1 producto no disponible';
    }

    if (totalProductsOutOfStock > 1) {
      subtitle = `${totalProductsOutOfStock} productos no disponibles`;
    }
    
    return (
      <div style={{ marginTop: '2em' }}>
        <Header text={headerText} subtitle={subtitle} />

        <p>
          Selecciona un producto para cambiar su disponibilidad
        </p>

        { this.state.menu.map((categoryProducts, index) =>
          <Card fluid key={index}>
            <Card.Content>
              <Card.Header>{categoryProducts[0]}</Card.Header>
              <Card.Description>
                <List divided>
                  { categoryProducts[1].map((product) =>
                    <List.Item
                      key={product.id}
                      style={{ cursor: 'pointer', padding: '1em 0', textDecoration: (product.out_of_stock ? 'line-through' : 'none') }}
                      onClick={ () => this.toggleProductAvailability(product) }
                    >
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            { product.name }
                          </Grid.Column>
                          <Grid.Column width={2}>
                            { product.out_of_stock ? (
                              <Icon name="circle" color="red" />
                            ) : (
                              <Icon name="check circle" color="green" />
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </List.Item>
                  )}
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        )}
      </div>
    )
  }
}
export default ManagerProductsPage;