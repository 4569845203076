import React, { useState } from 'react';
import { List, Grid, Form, Card, Input } from 'semantic-ui-react';
import formatter from 'utils/formatter';
import Header from 'components/Header';
import Message from 'components/Message';
import CancelGoButtons from 'components/CancelGoButtons';

export default function MicroBillSummary({ bill, orders, cancelFn, billPaidFn }) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (showConfirmation) {
    const formattedTotal = formatter.currency(bill.total);
    return (
      <div style={{ marginTop: '1em' }}>
        <Message headerText="confirmar cuenta pagada">
          <p style={{ textAlign: 'center', fontSize: '16px' }}>
            Confirma que la cuenta <b>#{bill.id}</b>
            <br/>
            para <b>{bill.note}</b>
            <br/>
            ha sido pagada por
            <br/>
            <b>{formattedTotal}</b>
          </p>

          <CancelGoButtons
            cancelFn={cancelFn}
            goText="confirmar cuenta pagada"
            goEnabled={true}
            goFn={billPaidFn}
            warning={true}
          />
        </Message>
      </div>
    )
  }

  return (
    <div>
      <Header text={`#${bill.id}`} subtitle={bill.note} inlineSubtitle={true} />

      <Card fluid>
        <Card.Content>
          <List divided relaxed>
            { orders.map((order) => 
              <List.Item key={order.id}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12} verticalAlign="top">
                      <p style={{ fontSize: '1em', marginBottom: 0 }}>
                        { order.product.name }
                      </p>
                      { (order.quantity > 1) &&
                        <p style={{ fontSize: '1em', marginBottom: 0 }}>
                          x {order.quantity}
                        </p>
                      }
                    </Grid.Column>
                    
                    <Grid.Column width={4} verticalAlign="top" textAlign="right">
                      <p style={{ fontSize: '1em', marginBottom: 0, color: (order.quantity > 1 ? 'gray' : '') }}>
                        { formatter.currency(order.product.price) }
                      </p>
                      { (order.quantity > 1) &&
                        <p style={{ fontSize: '1em', marginBottom: 0 }}>
                          {formatter.currency((order.quantity * order.product.price))}
                        </p>
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Item>
            )}
          </List>
        </Card.Content>
      </Card>
      
      <Card fluid>
        <Card.Header>
          <List relaxed>
            <List.Item>
              <div style={{ textAlign: 'right', padding: '1em', fontSize: '20px' }}>
                Total a pagar
                <span style={{ marginLeft: '1em', fontWeight: 'bold' }}>
                { formatter.currency(bill.total) }
                </span>
              </div>
            </List.Item>
          </List>
        </Card.Header>
      </Card>
      
      <CancelGoButtons
        cancelFn={cancelFn}
        goText="cuenta pagada"
        goFn={ () => setShowConfirmation(true) }
        goEnabled={true}
      />
    </div>
  )
}