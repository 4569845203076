import React, { useState, useEffect, useRef } from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import Header from 'components/Header';
import CancelGoButtons from 'components/CancelGoButtons';

export default function OrderNoteForm({ order, cancelFn, saveFn }) {
  const [notes, setNotes] = useState(order.notes || '');
  const [valid, setValid] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    if (notes && notes.length < 200) {
      setValid(true);
    } else {
      setValid(false);
    }

  }, [notes]);
  
  const handleNotes = (event) => {
    const notes = event.target.value;
    setNotes(notes);
  }

  const cancel = () => {
    cancelFn();
  }

  const save = () => {
    saveFn(order.uuid, notes);
  }

  const subtitle = order.product.name;

  return (
    <div>
      <Header text="Agregar notas a orden" subtitle={subtitle} />

      <Form>
        <Form.Field>
          <label>notas</label>
          <TextArea
            ref={textareaRef}
            onChange={handleNotes}
            value={notes}
          />
        </Form.Field>
        <CancelGoButtons
          cancelFn={cancel}
          goText="Guardar orden"
          goFn={save}
          goEnabled={valid}
        />
      </Form>
    </div>
  )
}