import React from 'react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import es from 'timeago.js/lib/lang/es';
import { Grid, Icon, Label, Button } from 'semantic-ui-react';

export default function Order({ order, toggleOrderDeliveredFn, assignOrderFn }) {
  timeago.register('es', es);
  const textDecorationStyle = order.delivered_at ? 'line-through' : 'none';
  
  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13} verticalAlign="top">
              <p style={{ marginBottom: 0, fontSize: '18px', textDecoration: textDecorationStyle }}>
                <span>
                  { order.product.name }
                </span>
                { order.dancer_id &&
                  <span style={{ marginLeft: '0.5em', color: 'gray' }}>
                    ({ order.dancer_name })
                  </span>
                }
                { (order.delivered_at !== null) &&
                  <span style={{ marginLeft: '0.5em' }}>
                    <Icon name="check" size="" color="green" />
                  </span>
                }
              </p>
              <p style={{ fontWeight: 'normal', color: 'grey' }}>
                { order.notes }
              </p>
            </Grid.Column>
            <Grid.Column width={3} verticalAlign="top" textAlign="right">
              { !order.delivered_at &&
                <Button
                  size="tiny"
                  circular
                  icon
                  onClick={ () => toggleOrderDeliveredFn(order.id) }
                >
                  <Icon name="check" />
                </Button>
              }

              { order.delivered_at &&
                <Button
                  size="tiny"
                  circular
                  icon
                  onClick={ () => toggleOrderDeliveredFn(order.id) }
                >
                  <Icon name="close" />
                </Button>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      { order.delivered_at &&
        <div>
          <p style={{ fontSize: '13px', color: 'gray', textAlign: 'left', marginTop: '5px' }}>
            <Icon name="clock outline" size='large' />
            <span style={{ marginRight: '3px' }}>
              entregado
            </span>
            <TimeAgo
              datetime={order.delivered_at}
              locale="es"
            />
          </p>
        </div>
      }
    </>
  )
}