import React, { useState, useEffect } from 'react';
import { Form, Input, Select } from 'semantic-ui-react';
import Header from 'components/Header';
import CancelGoButtons from 'components/CancelGoButtons';
import API from 'utils/api';

export default function BillForm({ cancelFn, submitFn, bill=null }) {
  const [tables, setTables] = useState([]);
  const [tableId, setTableId] = useState(bill?.table?.id || null);
  const [note, setNote] = useState(bill?.note || '');
  const [valid, setValid] = useState(false);

  const getTables = () => {
    API.getWaiterEstablishmentTables()
      .then((response) => {
        setTables(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleTableSelect = (event, data) => {
    const tableId = parseInt(data.value) || null;
    setTableId(tableId);
  }

  const handleNote = (event) => {
    const note = event.target.value;
    setNote(note);
  }

  const validate = () => {
    let valid = false;
    
    if (note) {
      valid = true;
    }

    if (tableId) {
      valid = true;
    }
    
    setValid(valid);
  }

  const submit = () => {
    if (valid) {
      if (bill) {
        submitFn(bill, tableId, note);
      } else {
        submitFn(tableId, note);
      }
    }
  }

  const tablesOptions = tables.map((table) => {
    return {
      key: table.id,
      text: table.name,
      value: table.id
    }
  });

  useEffect(() => {
    getTables();
  }, [])

  useEffect(() => {
    validate();
  }, [tableId, note])

  const goText = bill ? 'guardar cuenta' : 'crear cuenta';
  const headerText = bill ? `Cuenta #${bill.id}` : 'Cuenta nueva';

  return (
    <div>
      <Header text={headerText} subtitle="Agrega detalles a la cuenta" />
      
      <Form>
        <Form.Field>
          <label>ID del cliente</label>
          <Input
            value={note}
            onChange={handleNote}
            placeholder="Ej. Alex"
          />
        </Form.Field>

        <Form.Field>
          <label>mesa</label>
          <Select
            options={tablesOptions}
            value={tableId}
            onChange={handleTableSelect}
            placeholder="selecciona una mesa..."
          />
        </Form.Field>
        
        <CancelGoButtons
          cancelFn={cancelFn}
          goText={goText}
          goFn={submit}
          goEnabled={valid}
        />
      </Form>
    </div>
  )
}