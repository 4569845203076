import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import Pusher from 'pusher-js';
import { Button } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import es from 'timeago.js/lib/lang/es';
import API from 'utils/api';
import Message from 'components/Message';
import NewOrdersForm from './ui/NewOrdersForm';
import Bill from './ui/Bill';
import BillSummary from './ui/BillSummary';
import WaiterDancerTokenScanner from './ui/WaiterDancerTokenScanner';
import authHelpers from 'utils/authHelpers';

class WaiterBillPage extends React.Component {
  constructor(props) {
    super(props);
    
    const query = qs.parse(props.match.params, { ignoreQueryPrefix: true });
    this.billId = parseInt(query?.billId);
    
    timeago.register('es', es);
    
    this.state = {
      bill: null,
      newOrders: false,
      showSummary: false,
      orderToAssign: null
    };
  }
  
  componentDidMount() {
    this.getBill();
    
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      Pusher.logToConsole = true;
    }
    
    this.subscribeToPusherEvents();
  }

  subscribeToPusherEvents = () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true
    });
    
    const channelName = authHelpers.getChannelId();
    const channel = pusher.subscribe(channelName);
    
    channel.bind('orders-updated', (data) => {
      this.getBill();
    });
  }

  getBill = () => {
    API.getWaiterBill(this.billId)
      .then((response) => {
        this.setState({ bill: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createOrders = (orders) => {
    const orders_params = orders.map((order) => {
      return {
        product_id: order.product.id,
        dancer_id: order.dancer_id,
        notes: order.notes
      }
    });

    const params = { orders: orders_params };

    API.createWaiterOrders(this.billId, params)
      .then((response) => {
        this.setState({ newOrders: false }, () => {
          this.getBill();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  toggleOrderDelivered = (orderId) => {
    API.waiterToggleDeliveredOrder(orderId)
      .then((response) => {
        this.getBill();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  assignOrder = (data) => {
    const parts = data.split('/')
    const dancerToken = parts[parts.length-1];
    const order = this.state.orderToAssign;

    const params = {
      order_id: order.id,
      dancer_token_id: dancerToken,
    }

    API.assignWaiterOrder(order.id, params)
      .then((response) => {
        this.setState({ orderToAssign: null }, this.getBill);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  closeBill = () => {
    API.closeWaiterBill(this.billId)
      .then((response) => {
        this.props.history.push('/waiter/bills');
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {
    if (!this.state.bill) { return null }

    // when bill has already been closed (paid)
    if (this.state.bill.paid_at) {
      return (
        <Message headerText="Cuenta cerrada">
          <p className="help-text">
            Esta cuenta ya fue pagada y cerrada
            {' '}
            <TimeAgo
              datetime={this.state.bill.paid_at}
              locale="es"
            />.
          </p>
          <Link to={`/waiter/bills`}>
            <Button>
              Regresar
            </Button>
          </Link>
        </Message>
      )
    }

    // when adding new orders
    if (this.state.newOrders) {
      return (
        <NewOrdersForm
          cancelFn={ () => this.setState({ newOrders: false }) }
          submitFn={this.createOrders}
        />
      )
    }

    // when bill is going to be paid
    if (this.state.showSummary) {
      return (
        <BillSummary
          bill={this.state.bill}
          cancelFn={ () => this.setState({ showSummary: false }) }
          closeBillFn={this.closeBill}
        />
      )
    }

    // (default)
    return (
      <Bill
        bill={this.state.bill}
        newOrdersFn={ () => this.setState({ newOrders: true }) }
        showSummaryFn={ () => this.setState({ showSummary: true }) }
        toggleOrderDeliveredFn={this.toggleOrderDelivered}
        assignOrderFn={ (order) => this.setState({ orderToAssign: order }) }
      />
    )
  }
}

export default withRouter(WaiterBillPage);