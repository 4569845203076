import React from 'react';
import { Grid, List, Button, Icon, Label } from 'semantic-ui-react';
import CancelGoButtons from 'components/CancelGoButtons';
import { v4 as uuid } from 'uuid';
import API from 'utils/api';
import Header from 'components/Header';
import Menu from './Menu';
import OrderNoteForm from './OrderNoteForm';
import WaiterDancerList from './WaiterDancerList';

class NewOrdersForm extends React.Component {
  constructor(props) {
    super(props);
    
    this.orderNotesTextAreaRef = React.createRef();
    
    this.state = {
      orders: [],
      addingNewOrder: true,
      selectedOrder: null,
      selectedOrderToAssign: null
    };
  }

  addOrder = (product) => {
    const newOrder = {
      uuid: uuid(),
      bill_id: this.billId,
      product: product,
      notes: '',
    }
    
    this.setState((prevState) => {
      const newOrders = [...prevState.orders, newOrder];

      return {
        orders: newOrders,
        addingNewOrder: false,
        selectedOrder: null,
        selectedOrderToAssign: null
      }
    });
  }

  editOrder = (order) => {
    this.setState({ selectedOrder: order });
  }

  updateOrderNotes = (orderUuid, notes) => {
    this.setState((prevState) => {
      const newOrders = prevState.orders.map((order) => {
        if (order.uuid === orderUuid) {
          order.notes = notes;
        }
        return order
      });

      return {
        orders: newOrders,
        addingNewOrder: false,
        selectedOrder: null,
        selectedOrderToAssign: null
      }
    });
  }

  assignOrder = (dancer) => {
    this.setState((prevState) => {
      const newOrders = prevState.orders.map((order) => {
        if (order.uuid === this.state.selectedOrderToAssign.uuid) {
          order.dancer_id = dancer.id;
          order.dancer_name = dancer.name;
        }
        return order
      });

      return {
        orders: newOrders,
        addingNewOrder: false,
        selectedOrder: null,
        selectedOrderToAssign: null
      }
    });
  }

  removeOrder = (uuid) => {
    this.setState((prevState) => {
      const newOrders = prevState.orders.filter((order) => {
        return order.uuid !== uuid
      });

      return { orders: newOrders }
    });
  }

  submit = () => {
    this.props.submitFn(this.state.orders);
  }

  validate = () => {
    let valid = true;

    if (this.state.orders.length < 1) {
      valid = false;
    }
    
    this.state.orders.forEach((order) => {
      if (order.product.treat) {
        if (!order.dancer_id) {
          valid = false;
        }
      }
    })

    return valid
  }
  
  render() {

    // when adding a note to an existing order
    if (this.state.selectedOrder) {
      return (
        <OrderNoteForm
          order={this.state.selectedOrder}
          cancelFn={ () => this.setState({ selectedOrder: null }) }
          saveFn={this.updateOrderNotes}
        />
      )
    }

    // when assigning an order to a dancer
    if (this.state.selectedOrderToAssign) {
      return (
        <WaiterDancerList
          order={this.state.selectedOrderToAssign}
          cancelFn={ () => this.setState({ selectedOrderToAssign: null }) }
          onDancerSelectedFn={this.assignOrder}
        />
      )
    }

    // when adding a new order
    if (this.state.addingNewOrder) {
      return (
        <Menu
          cancelFn={ () => this.setState({ addingNewOrder: false }) }
          addOrderFn={this.addOrder}
        />
      )
    }
    
    // when there are no products added
    let content = (
      <List.Item style={{ padding: '1em 0' }} key={0}>
        Sin productos agregados a comanda.
      </List.Item>
    );
    
    // when there are products already added
    if (this.state.orders.length > 0) {
      content = this.state.orders.map((order) => {
        return (
          <List.Item style={{ padding: '1em 0' }} key={order.uuid}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={11} verticalAlign="middle">
                  <div style={{ marginBottom: 0 }}>
                    <span style={{ fontSize: '18px' }}>
                      { order.product.name }
                    </span>
                    { order.product.treat &&
                      <span style={{ marginLeft: '0.5em' }}>
                        { order.dancer_id ? (
                          <span style={{ color: 'gray' }}>
                            para {order.dancer_name}
                          </span>
                        ) : (
                          <Label onClick={ () => this.setState({ selectedOrderToAssign: order }) }>
                            sin asignar
                          </Label>
                        )}
                      </span>
                    }
                  </div>
                  { (this.state.orderBeingEditedUUID !== order.uuid) &&
                    <p style={{ fontWeight: 'normal', color: 'gray' }}>
                      { order.notes }
                    </p>
                  }
                </Grid.Column>
                
                <Grid.Column width={5} verticalAlign="middle" textAlign="right">
                  <div>
                    <Button icon circular onClick={ () => this.editOrder(order) }>
                      <Icon name="sticky note outline" />
                    </Button>
                    <Button icon circular onClick={ () => this.removeOrder(order.uuid) }>
                      <Icon name="delete" />
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </List.Item>
        )
      });
    }

    return (
      <div>
        <Header text="Nueva comanda">
          <Button circular icon primary onClick={ () => this.setState({ addingNewOrder: true }) }>
            <Icon name="plus" />
          </Button>
        </Header>
        
        <List divided>
          { content }
        </List>

        <CancelGoButtons
          cancelFn={this.props.cancelFn}
          goText="crear comanda"
          goFn={this.submit}
          goEnabled={this.validate()}
        />
      </div>
    )
  }
}

export default NewOrdersForm;