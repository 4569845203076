import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, List, Button, Icon } from 'semantic-ui-react';
import Header from 'components/Header';
import Order from './Order';
import CancelGoButtons from 'components/CancelGoButtons';

export default function Bill({ bill, newOrdersFn, showSummaryFn, toggleOrderDeliveredFn, assignOrderFn }) {
  const history = useHistory();
  const valid = bill.orders.length > 0;
  
  let billHeader = '';
          
  if (bill.note) {
    billHeader = bill.note;

    if (bill.table) {
      billHeader = `${bill.note} (${bill.table.name.toLowerCase()})`;
    }
  }
  else {
    billHeader = bill.table.name.toLowerCase();
  }
  
  let content = (
    <List.Item style={{ marginBottom: '0.5em' }} key={0}>
      Sin ordenes.
    </List.Item>
  )

  if (valid) {
    content = bill.orders.map((order) => {
      return (
        <List.Item style={{ marginBottom: '0.5em' }} key={order.id}>
          <Order
            order={order}
            toggleOrderDeliveredFn={toggleOrderDeliveredFn}
            assignOrderFn={assignOrderFn}
          />
        </List.Item>
      )
    });
  }
  
  return (
    <div>
      <Header text={billHeader} subtitle={`#${bill.id}`}>
        <Button circular icon primary onClick={newOrdersFn}>
          <Icon name="plus" />
        </Button>
      </Header>
      
      <Card fluid>
        <Card.Content>
          <List divided>
            { content }
          </List>
        </Card.Content>
      </Card>

      <CancelGoButtons
        cancelText="regresar"
        cancelFn={ () => history.push('/waiter/bills') }
        goText="cobrar cuenta"
        goFn={showSummaryFn}
        goEnabled={valid}
      />
    </div>
  )
}