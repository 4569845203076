import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon, Label } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import formatter from 'utils/formatter';

export default function MicroOpenBillCard({ bill }) {
  const isMoreThanOneMinuteAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = (now - date) / 1000;
    return differenceInSeconds > 60;
  }
  
  let remaining = bill.orders.length;

  bill.orders.forEach((order) => {
    if (order.ready_at) {
      remaining -= 1;
    }
  })

  return (
    <Card
      fluid
      key={bill.id}
      as={Link}
      to={`/micro/bills/${bill.id}`}
      style={{ marginBottom: '1.5em' }}
    >
      <Card.Content
        style={{ cursor: 'pointer' }}
      >
        <Card.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>
              { bill.note }
            </span>
            { bill.paid_at ? (
              <Label color="green">
                pagado
              </Label>
            ) : (
              <Label color="red">
                sin pagar
              </Label> 
            )}
          </div>
        </Card.Header>
        <Card.Meta>
          <span style={{ fontSize: '16px', color: (remaining === 0 ? 'green' : '') }}>
            #{bill.id}
          </span>
          { (remaining === 0) &&
            <Icon name="check" color="green" />
          }
        </Card.Meta>
        <Card.Description>
          { bill.orders.map((order) => {
            const textDecorationStyle = order.ready_at ? 'line-through' : 'none';
            const textColorStyle = order.ready_at ? 'darkgray' : '';
            return (
              <div key={order.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0' }}>
                <p style={{ marginBottom: 0, color: textColorStyle, fontSize: '16px', textDecoration: textDecorationStyle }}>
                  <b style={{ marginRight: '0.5em' }}>
                    { order.quantity }
                  </b>
                  <span>
                    { order.product_name }
                  </span>    
                </p>
              </div>
            )
          })}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            { isMoreThanOneMinuteAgo(bill.created_at) ? (
              <>
                <Icon name="clock outline" size='large' />
                <TimeAgo
                  datetime={bill.created_at}
                  locale="es"
                />
              </>
            ) : (
              <span style={{ marginRight: '3px' }}>
                Recién ordenado
              </span>
            )}
          </div>
          <span style={{ fontWeight: 'normal', fontSize: '18px' }}>
            { formatter.currency(bill.total) }
          </span>
        </div>
      </Card.Content>
    </Card>
  )
}